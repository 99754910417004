/* eslint-disable react-hooks/exhaustive-deps */
/** @jsxImportSource @emotion/react */

import React, { useCallback, useState } from "react";
import { css } from "@emotion/react";
import { Form, Button } from "react-bootstrap";
import { charTest, emailTest, phoneNumberTest } from "@common/util";
import { useMediaQuery } from "react-responsive";

// 메일관련
import emailjs from "emailjs-com";
import Swal from "sweetalert2";
import { toast } from "react-toastify";

function EmailForm() {
  const isSmallScreen = useMediaQuery({ maxWidth: 1024 });

  // 초기값
  const [prjTypeText, setPrjTypeText] = useState("서비스개발");
  const [inputs, setInputs] = useState({
    name: "",
    email: "",
    phoneNumber: "",
    content: "",
    prjType: "dev-service",
  });
  const labels = ["성함", "이메일", "연락처", "프로젝트 내용"];

  // input 상태관리
  const { prjType, name, email, phoneNumber, content } = inputs;
  const handleChange = (e) => {
    const { value, name } = e.target;
    setInputs({
      ...inputs,
      [name]: value.replace(/(\n|\r\n)/g, "\n"),
    });
    if (name === "prjType") {
      setPrjTypeText(e.target.nextSibling.innerText);
    }
  };

  // reset
  const handleReset = useCallback(() => {
    setInputs({
      name: "",
      email: "",
      phoneNumber: "",
      content: "",
      prjType: "dev-service",
    });
    setPrjTypeText("서비스개발");
  }, []);

  // 메일보내기
  const sendEmail = useCallback(
    (e) => {
      e.preventDefault();
      const inputArr = Object.entries(inputs);

      // 빈 값 확인
      for (let i in inputArr) {
        const value = inputArr[i][1];
        // console.log(value)
        // console.log(labels[i-1]);
        if (value === "") {
          return toast.warning(`${labels[i]}을(를) 입력해주세요.`, {
            theme: "colored",
            autoClose: 3000,
            position: "top-right",
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: false,
          });
        }
      }

      // 정규식확인
      if (!charTest(inputs["name"])) {
        return toast.warning(`성함이 올바르지 않습니다.`, {
          theme: "colored",
          autoClose: 3000,
          position: "top-right",
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: false,
        });
      }
      if (!emailTest(inputs["email"])) {
        return toast.warning(`이메일이 올바르지 않습니다.`, {
          theme: "colored",
          autoClose: 3000,
          position: "top-right",
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: false,
        });
      }
      if (!phoneNumberTest(inputs["phoneNumber"])) {
        return toast.warning(`연락처가 올바르지 않습니다.`, {
          theme: "colored",
          autoClose: 3000,
          position: "top-right",
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: false,
        });
      }

      // 문의하기
      Swal.fire({
        title: "문의하기",
        text: "작성한 내용으로 문의하시겠습니까?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "var(--bs-primary)",
        cancelButtonColor: "var(--bs-gray)",
        confirmButtonText: "확인",
        cancelButtonText: "취소",
      }).then((result) => {
        if (result.isConfirmed) {
          // 메일보내기
          const data = Object.assign(inputs, { prjTypeText: prjTypeText });
          // console.log(data);
          emailjs.send("service_kzrm8fk", "template_bm8hicl", data, "user_cm5uQBgA6wKdpTzr5boFI").then(
            (response) => {
              // console.log('SUCCESS!', response.status, response.text);
              if (response.status === 200) {
                Swal.fire({
                  html: `[${response.status}] 문의를 보내주셔서 감사합니다.<br />발송을 완료했습니다.`,
                  confirmButtonColor: "var(--bs-primary)",
                  confirmButtonText: "확인",
                });
                handleReset();
              }
            },
            (error) => {
              // console.log('ERROR..', error);
              Swal.fire({
                html: `발송실패. 다시 시도해주세요.<br />${error}`,
                confirmButtonColor: "var(--bs-primary)",
                confirmButtonText: "확인",
              });
            }
          );
        }
      });
    },
    [inputs, isSmallScreen, labels, prjTypeText]
  );

  return (
    <>
      <Form css={emailForm} onSubmit={sendEmail}>
        <div className="form-box">
          <h4>프로젝트 선택</h4>
          <Form.Group>
            <Form.Check
              label="서비스개발"
              name="prjType"
              type="radio"
              id="dev-service"
              value="dev-service"
              onChange={handleChange}
              checked={prjType === "dev-service"}
            />
            <Form.Check
              label="블록체인컨설팅"
              name="prjType"
              type="radio"
              id="consult-blockchain"
              value="consult-blockchain"
              onChange={handleChange}
              checked={prjType === "consult-blockchain"}
            />
            <Form.Check
              label="파트너쉽제안"
              name="prjType"
              type="radio"
              id="proposal-partnership"
              value="proposal-partnership"
              onChange={handleChange}
              checked={prjType === "proposal-partnership"}
            />
            <Form.Check
              label="입사관련"
              name="prjType"
              type="radio"
              id="employment"
              value="employment"
              onChange={handleChange}
              checked={prjType === "employment"}
            />
            <Form.Check label="기타" name="prjType" type="radio" id="etc" value="etc" onChange={handleChange} checked={prjType === "etc"} />
          </Form.Group>
        </div>
        <div className="form-box">
          <h4>연락을 위한 정보</h4>
          <div>
            <Form.Group className="form-row">
              <Form.Label>
                성함<span>*</span>
              </Form.Label>
              <Form.Control type="text" placeholder="성함을 입력해주세요.(특수문자/숫자 불가능)" name="name" value={name} onChange={handleChange} />
            </Form.Group>
            <Form.Group className="form-row">
              <Form.Label>
                이메일<span>*</span>
              </Form.Label>
              <Form.Control type="text" placeholder="이메일을 입력해주세요." name="email" value={email} onChange={handleChange} />
            </Form.Group>
            <Form.Group className="form-row">
              <Form.Label>
                연락처<span>*</span>
              </Form.Label>
              <Form.Control type="text" placeholder="연락처를 입력해주세요." name="phoneNumber" value={phoneNumber} onChange={handleChange} />
            </Form.Group>
            <Form.Group className="form-row">
              <Form.Label>
                프로젝트 내용<span>*</span>
              </Form.Label>
              <Form.Control as="textarea" rows={6} placeholder="프로젝트 내용을 입력해주세요." name="content" value={content} onChange={handleChange} />
            </Form.Group>
          </div>
        </div>
        <div className="form-box btn-area">
          <Button variant="primary" type="submit">
            문의하기
          </Button>
        </div>
      </Form>
    </>
  );
}
const emailForm = css`
  .form-box {
    display: flex;
    align-items: flex-start;
    border-top: 1px solid var(--bs-border);
    padding: 2rem 0;
    & > h4 {
      font-size: 1.75rem;
      font-weight: 700;
      flex: 360px 0 0;
    }
    & > div {
      flex: 1;
      margin-top: 0.5rem;
      & > .form-row {
        margin-top: 1.5rem;
        &:nth-of-type(1) {
          margin-top: 0;
        }
        label {
          font-weight: 700;
          font-size: 1.25rem;
          line-height: 1.5rem;
          font-weight: 700;
          span {
            font-family: "Noto Sans KR";
            margin-left: 0.15rem;
            color: var(--bs-red);
          }
        }
        .form-control {
          min-height: 50px;
          border-radius: 0;
          font-size: 1.125rem;
          &::placeholder {
            color: var(--bs-gray-500);
          }
        }
      }
    }
    .form-check {
      padding-left: 0;
      & + .form-check {
        margin-top: 0.75rem;
      }
      input {
        width: 0;
        opacity: 0;
        &:checked {
          & ~ label {
            &:after {
              transform: scale(1);
            }
          }
        }
        &:focus {
          &:before {
            outline-color: rgba(173, 196, 255, 0.75);
            outline-style: solid;
            outline-width: 5px;
          }
        }
      }
      label {
        cursor: pointer;
        font-size: 1.25rem;
        font-weight: 500;
        padding-left: 2rem;
        position: relative;
        &:before {
          content: "";
          display: block;
          width: 24px;
          height: 24px;
          border: 2px solid var(--bs-dark);
          border-radius: 100%;
          position: absolute;
          top: calc(50% - 12px);
          left: 0;
        }
        &:after {
          content: "";
          display: block;
          width: 12px;
          height: 12px;
          background: var(--bs-dark);
          border-radius: 100%;
          position: absolute;
          top: calc(50% - 6px);
          left: 6px;
          transform: scale(0);
          transform-origin: center;
          transition: 0.2s ease-in-out;
        }
      }
    }
    &.btn-area {
      justify-content: flex-end;
      // justify-content: center;
      .btn {
        padding: 0.8rem 2.5rem;
        border-radius: 100px;
        font-weight: 500;
        font-size: 1.25rem;
      }
    }
  }
  @media (max-width: 1024px) {
    .form-box {
      flex-direction: column;
      align-items: stretch;
      border-top: 0;
      padding: 2rem 0 0;
      & > h4 {
        font-size: 1.25rem;
        flex: auto 0 0;
      }
      & > div {
        flex: auto 0 0;
        width: 100%;
        border-top: 1px solid var(--bs-border);
        padding: 1rem 0;
        margin-top: 0.75rem;
        & > .form-row {
          label {
            font-size: 1rem;
          }
          .form-control {
            min-height: 40px;
            font-size: 1rem;
          }
        }
      }
      .form-check {
        & + .form-check {
          margin-top: 1rem;
        }
        label {
          font-size: 1rem;
          &:before {
            width: 20px;
            height: 20px;
            top: calc(50% - 10px);
          }
          &:after {
            width: 8px;
            height: 8px;
            top: calc(50% - 4px);
            left: 6px;
          }
        }
      }
      &.btn-area {
        padding: 0;
        justify-content: center;
      }
    }
  }
`;
export default EmailForm;
