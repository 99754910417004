/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useLayoutEffect, useRef, useCallback } from "react";
import { Container, Spinner, Row } from "react-bootstrap";
import { firebase_db } from "../../firebaseConfig";
import { useHistory } from "react-router";
import SlideUpText from "@components/SlideUpText";

// animate
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { Reveal } from "react-gsap";
import FadeInBottom from "@components/FadeInBottom";

// image
import IcNext from "@assets/images/ic_next.svg";

export default React.memo(function WorksDetail(props) {
  const history = useHistory();

  const { id } = props.match.params;

  const [work, setWork] = useState(null);
  const [workTypeArr, setWorkTypeArr] = useState([]);
  const [nextWork, setNextWork] = useState({});
  const [usePinWrapTopArr, setUsePinWrapTopArr] = useState([]);
  const [usePinWrapArr, setUsePinWrapArr] = useState([]);
  const [usePinBoxArr, setUsePinBoxArr] = useState([]);
  const [usePinMarkersArr, setUsePinMarkersArr] = useState([]);
  const [useNewContentArr, setUseNewContentArr] = useState([]);

  const isInitialMount_01 = useRef(true);
  const isInitialMount_02 = useRef(true);

  const visualRef = useRef();
  const mockupRef = useRef();
  const ParallaxRef = useRef();
  const pinMarkersArr = useRef([]);
  const pinWrapArr = useRef([]);
  const pinBoxArr = useRef([]);
  const pinContentArr = useRef([]);
  pinMarkersArr.current[0] = [];
  pinMarkersArr.current[1] = [];

  // set animate
  const setAnimate = () => {
    let markersTop = [];
    let usePinWrap = [];
    let usePinBox = [];
    let usePinMarkers = [];

    // null check, array rebuild
    usePinWrap = pinWrapArr.current.filter((pinwrap) => pinwrap !== null);
    usePinBox = pinBoxArr.current.filter((pinbox) => pinbox !== null);
    for (let i in pinMarkersArr.current) {
      usePinMarkers.push(pinMarkersArr.current[i].filter((marker) => marker !== null));
    }

    // submit changeContens fnc
    for (let i in usePinWrap) {
      markersTop.push(usePinWrap[i].getBoundingClientRect().top);
      changeContents(usePinMarkers[i], workTypeArr[i], i);
    }

    // set use states
    setUsePinWrapTopArr(markersTop);
    setUsePinWrapArr(usePinWrap);
    setUsePinBoxArr(usePinBox);
    setUsePinMarkersArr(usePinMarkers);
    setUseNewContentArr(newContentArr);
  };

  // change contents fnc
  let newContentArr = [];
  const changeContents = (arr, type, idx) => {
    let newContent = document.querySelector(`#target-${id}-${type}0`);

    // 동작정의
    if (arr.length > 0) {
      arr.forEach((marker) => {
        marker.content = document.querySelector(`#${marker.dataset.markerContent}`);
        marker.content.enter = function () {
          gsap.fromTo(
            marker.content,
            {
              y: 20,
            },
            {
              autoAlpha: 1,
              y: 0,
              duration: 0.3,
            }
          );
        };
        marker.content.leave = function () {
          gsap.to(marker.content, {
            autoAlpha: 0,
            duration: 0.3,
          });
        };
      });
    }
    // push newContent Arr
    newContentArr.push(newContent);
  };

  // update pin panel(onUpdate fnc) - change pin contents
  let lastContentArr = [];
  const updatePinPanel = (index) => {
    let currScroll = window.scrollY;
    if (usePinMarkersArr[0][0] !== null) {
      usePinMarkersArr[index].forEach((marker) => {
        let markerPosition = usePinWrapTopArr[index] + marker.offsetTop;
        if (currScroll > markerPosition - 80) {
          useNewContentArr[index] = marker.content;
        }
      });
      if (useNewContentArr[index] && (lastContentArr[index] == null || !useNewContentArr[index].isSameNode(lastContentArr[index]))) {
        if (lastContentArr[index]) {
          lastContentArr[index].leave();
        }
        useNewContentArr[index].enter();
        lastContentArr[index] = useNewContentArr[index];
      }
    }
  };

  // nextWork tab name change
  const handleTypeText = useCallback((type) => {
    switch (type) {
      case "bc":
        return "Blockchain";
      case "ai":
        return "Artificial Intelligence";
      case "ec":
        return "E-Commerce Solution";
      case "ict":
        return "ICT Technology Convergence";
      default:
        return "";
    }
  }, []);

  const onClickGoList = () => {
    props.history.push("/works/list");
  };
  const onClickNextWork = () => {
    history.push(`/works/detail/${nextWork.id}`);
    // go scroll top
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "instant",
    });
  };

  // ----------------------------------------------------------------------------------------------
  // ----------------------------------------------------------------------------------------------
  // work data
  useLayoutEffect(() => {
    // reset
    if (usePinWrapArr.length > 0 && ScrollTrigger.getById("changeAnimate0") !== undefined) {
      for (let i in usePinWrapArr) {
        ScrollTrigger.getById(`changeAnimate${i}`).kill();
      }
    }
    if (usePinBoxArr.length > 0 && ScrollTrigger.getById("pinAnimate0") !== undefined) {
      for (let i in usePinBoxArr) {
        ScrollTrigger.getById(`pinAnimate${i}`).kill();
      }
    }
    setWork(null);
    setWorkTypeArr([]);
    setUsePinWrapTopArr([]);
    setUsePinWrapArr([]);
    setUsePinBoxArr([]);
    isInitialMount_01.current = true;
    isInitialMount_02.current = true;

    // dataset
    const dbRef = firebase_db.ref("/works");
    dbRef.once("value").then((snapshot) => {
      let data = snapshot.val();
      let thisData = data.find((item) => {
        return item.id === id;
      });
      let nextDataIndex = data.indexOf(thisData) + 1;

      setWork(thisData);
      setNextWork(data[nextDataIndex]);
      thisData.detail ? setWorkTypeArr(Object.keys(thisData.detail)) : setWorkTypeArr([]);
    });
    return () => {
      dbRef.off();
    };
  }, [id, props.location.pathname]);

  // animate - cover, init
  useEffect(() => {
    if (isInitialMount_01.current) {
      isInitialMount_01.current = false;
    } else {
      // animate visual init
      gsap.fromTo(
        ParallaxRef.current,
        {
          opacity: 0,
          scale: 1.5,
        },
        {
          opacity: 1,
          scale: props.isSmallScreen ? 1.1 : 1,
          ease: "back.out(0.1)",
          duration: 2,
          delay: 0.2,
        }
      );
      if (mockupRef.current) {
        gsap.fromTo(
          mockupRef.current,
          {
            opacity: 0,
            y: 100,
          },
          {
            opacity: 1,
            y: 0,
            ease: "back.out(0.1)",
            duration: 0.8,
            delay: 0.8,
          }
        );
      }

      // 애니메이션 진행 전 setting
      setAnimate();
    }
  }, [workTypeArr]);

  // animate - pin, contents change
  useEffect(() => {
    if (isInitialMount_02.current) {
      isInitialMount_02.current = false;
    } else {
      if (usePinWrapTopArr.length > 0) {
        // animate change panel
        usePinWrapArr.forEach((panel, i) => {
          ScrollTrigger.create({
            id: `changeAnimate${i}`,
            trigger: panel,
            start: "top bottom",
            end: "100% 300px",
            onUpdate: () => {
              updatePinPanel(i);
            },
            // markers: true,
          });
        });
      }
      if (usePinBoxArr.length > 0) {
        usePinBoxArr.forEach((box, i) => {
          ScrollTrigger.create({
            id: `pinAnimate${i}`,
            trigger: box,
            start: "top 190px",
            end: "100%-=100px 300px",
            pin: pinContentArr.current[i],
            // markers: true,
          });
        });
      }
    }
    return () => {
      // cleanup
      if (usePinWrapArr.length > 0 && ScrollTrigger.getById("changeAnimate0") !== undefined) {
        for (let i in usePinWrapArr) {
          ScrollTrigger.getById(`changeAnimate${i}`).kill();
        }
      }
      if (usePinBoxArr.length > 0 && ScrollTrigger.getById("pinAnimate0") !== undefined) {
        for (let i in usePinBoxArr) {
          ScrollTrigger.getById(`pinAnimate${i}`).kill();
        }
      }
    };
  }, [usePinWrapTopArr]);

  return (
    <main id="works-detail">
      {work !== null ? (
        <>
          {work.visualMockup && (
            <div className={`${work.visualMockup.type} mockup-box`} ref={mockupRef}>
              <figure style={{ backgroundImage: `url(${work.visualMockup.imgUrl})` }}></figure>
            </div>
          )}
          {/* VISUAL */}
          <div className="panel visual" ref={visualRef}>
            <section>
              <Container>
                <div className="typo">
                  <SlideUpText delay="1.5">
                    <h1 className="title-bold">{work.title}</h1>
                  </SlideUpText>
                  {work.summary && (
                    <SlideUpText delay="2.0">
                      <h3 className="sub-title-white">{work.summary}</h3>
                    </SlideUpText>
                  )}
                </div>
              </Container>
              <div className="coverImg" style={{ backgroundImage: `url(${work.visual})` }} ref={ParallaxRef}></div>
              <div className="cover"></div>
            </section>
          </div>
          <div className="panel bottom">
            {/* OVERVIEW  */}
            <section className="overview">
              <Container>
                <Reveal>
                  <FadeInBottom>
                    <h3 className="sub-title-gray">OVERVIEW</h3>
                  </FadeInBottom>
                </Reveal>
                <Reveal threshold={0.2}>
                  <FadeInBottom>
                    <div className="content">
                      {work.subTitle && <h2 className="title-bold-02">{work.subTitle}</h2>}
                      <div>
                        <h5>{work.description}</h5>
                        <div className="dlBox">
                          {work.info &&
                            work.info !== null &&
                            work.info.length > 0 &&
                            work.info.map((info, index) => {
                              return (
                                <dl key={`info_${index}`}>
                                  <dt>{info.title}</dt>
                                  <dd>
                                    {info.content != null &&
                                      info.content.length > 0 &&
                                      info.content.map((content, index) => {
                                        return <p key={`info_content_${index}`}>{content}</p>;
                                      })}
                                  </dd>
                                </dl>
                              );
                            })}
                        </div>
                      </div>
                    </div>
                  </FadeInBottom>
                </Reveal>
              </Container>
            </section>

            {work.detail &&
              work.detail !== null &&
              Object.entries(work.detail).map((object, index) => {
                let key = object[0];
                let detail = object[1];
                return (
                  <section className={index === 0 ? "pin-area bg-gray-100" : "pin-area"} ref={(el) => (pinWrapArr.current[index] = el)} key={`detail-${key}`}>
                    <Container>
                      <Reveal>
                        <FadeInBottom>
                          <h3 className="sub-title-gray">{detail.title}</h3>
                          <p
                            className="small-device-text"
                            dangerouslySetInnerHTML={{
                              __html: detail.sutTitle,
                            }}
                          ></p>
                        </FadeInBottom>
                      </Reveal>
                      <div className="content">
                        <div
                          className={Object.entries(work.detail).length > 1 && index === 0 ? (!props.isSmallScreen ? "pin-box reverse" : "pin-box") : "pin-box"}
                          ref={(el) => (pinBoxArr.current[index] = el)}
                        >
                          <div className="pin-content" ref={(el) => (pinContentArr.current[index] = el)}>
                            {detail.text !== null &&
                              detail.text.map((text, idx) => {
                                return (
                                  <div key={`detail-text-${work.id}-${idx}`} id={`target-${work.id}-${key}${idx}`} className="typo">
                                    <h2>{text.title}</h2>
                                    <span>{text.subtext}</span>
                                  </div>
                                );
                              })}
                          </div>
                          <div className={`scroll-content mockup-wrap ${workTypeArr[index]}`}>
                            {detail.img !== null &&
                              detail.img.map((imgPath, idx) => {
                                return (
                                  <div
                                    key={`detail-img-${idx}`}
                                    id={`detail-img-${work.id}-${key}${idx}`}
                                    ref={(el) => (pinMarkersArr.current[index][idx] = el)}
                                    data-marker-content={`target-${work.id}-${key}${idx}`}
                                    className={`mockup-box ${workTypeArr[index]}`}
                                  >
                                    <figure
                                      className="mockup"
                                      style={{
                                        backgroundImage: `url(${imgPath})`,
                                      }}
                                    ></figure>
                                  </div>
                                );
                              })}
                          </div>
                        </div>
                      </div>
                    </Container>
                  </section>
                );
              })}

            {/* NEXT WORK */}
            <section className="next-work">
              <Container>
                <button className="btn-list" href="#none" onClick={() => onClickGoList()}>
                  목록으로
                </button>
                {nextWork && nextWork !== null ? (
                  // <div
                  //   className="next"
                  //   onClick={() =>
                  //     window.location.replace(`/works/detail/${nextWork.id}`)
                  //   }
                  // >
                  <div className="work-info" onClick={() => onClickNextWork()}>
                    <h3>{nextWork.title}</h3>
                    <div>
                      {nextWork.tab !== undefined &&
                        nextWork.tab.length > 0 &&
                        nextWork.tab.map((type, index) => {
                          return <span key={`type_${index}`}>{handleTypeText(type)}</span>;
                        })}
                    </div>
                    <button>
                      <IcNext />
                    </button>
                  </div>
                ) : (
                  <div className="work-info">
                    <div>
                      <span>마지막 프로젝트입니다.</span>
                    </div>
                  </div>
                )}
              </Container>
            </section>
          </div>
        </>
      ) : (
        <Row className="spinner-inner">
          <Spinner animation="border" variant="primary" />
        </Row>
      )}
    </main>
  );
});
