import React from "react";
import { Container, Accordion } from "react-bootstrap";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// animate
import { Reveal } from "react-gsap";
import FadeInBottom from "@components/FadeInBottom";

// image
import IcNavi from "@assets/images/ic_navi.svg";

// component
import EmailForm from "./components/EmailForm";

export default React.memo(function Contact(props) {
  return (
    <main id="contact" className="noVisual">
      <Container>
        <Reveal>
          <FadeInBottom>
            <div className="title-area">
              <h5>프로젝트 문의하기</h5>
              <h2>
                아래 양식을 작성한 후{props.isSmallScreen ? <br /> : " "}
                메시지를 보내주세요!
                <br />
                담당자가 24시간 내에 답변드립니다.
              </h2>
            </div>
            <div className="form-wrap">
              <EmailForm />
            </div>
          </FadeInBottom>
        </Reveal>

        <Reveal threshold={0.4}>
          <FadeInBottom>
            <div className="information">
              <div className="mt-0">
                <h5>간편하게 메일로 문의하세요!</h5>
                <strong className="title-bold-03 haveUnderline">hello@upchain.kr</strong>
              </div>
              <div>
                <h5>전화로 문의주시면 더 자세히 답변드립니다.</h5>
                <strong className="title-bold-03">(051)1522-7913</strong>
              </div>
              <div>
                <h5>방문을 원하신다면 위치를 확인해주세요.</h5>
                <dl>
                  <dt>
                    <IcNavi />
                    본사
                  </dt>
                  <dd>부산광역시 남구 전포대로 133, 위워크 14층</dd>
                </dl>
                <dl>
                  <dt>
                    <IcNavi />
                    기업부설연구소
                  </dt>
                  <dd>부산광역시 부산진구 전포대로199번길 15 현대타워오피스텔 1103호</dd>
                </dl>
              </div>
            </div>
          </FadeInBottom>
        </Reveal>
      </Container>

      <div id="faq">
        <Container>
          <Reveal threshold={0.4}>
            <FadeInBottom>
              <h3 className="title-bold-02">FAQ</h3>
              <Accordion defaultActiveKey="0" flush>
                {faqData !== null &&
                  faqData !== undefined &&
                  faqData.map((faq, index) => {
                    return (
                      <Accordion.Item eventKey={index} key={`faq_${index}`}>
                        <Accordion.Header>{faq.subject}</Accordion.Header>
                        <Accordion.Body dangerouslySetInnerHTML={{ __html: faq.content }}></Accordion.Body>
                      </Accordion.Item>
                    );
                  })}
              </Accordion>
            </FadeInBottom>
          </Reveal>
        </Container>
      </div>

      <ToastContainer colored />
    </main>
  );
});

// FAQ 데이터
const faqData = [
  {
    subject: "기획서가 없어도 프로젝트 진행이 가능할까요?",
    content:
      "네, 가능합니다!<br />주식회사 유피체인의 [스타트업 파트너쉽 프로그램]은 아이디어만 들고 오셔도 풍부한 경험과 여러 서비스/시스템을 개발하고 구축한 이력을 토대로 아이디어에 현실성을 부여하고 앞으로의 방향성, 시장성, 성장성 등을 고려하여 맞춤 컨설팅을 진행합니다. 이후 전문적인 경력팀에게 UI/UX 기획과 디자인이 맡겨지며, 고객사의 피드백 반영이 완료되면 개발부서로 인계됩니다.",
  },
  {
    subject: "개발 일정이나 금액은 어떻게 산정되고 진행되나요?",
    content:
      "이메일 또는 전화로 개발 문의를 하시면 유피체인에서 내부 회의를 통해 개발 여부를 판단한 후 연락을 드립니다. 이후 유피체인에 내방(부산본사) 또는 화상회의(원격미팅)를 통해 상담합니다. 개발 문의가 시작되는 제일 첫 미팅에서는 전문 컨설턴트에게 사업성 검토 및 비즈니스 컨설팅을 받습니다.",
  },
  {
    subject: "사업 아이디어가 노출되지는 않나요?",
    content: "유피체인은 상담 시작과 동시에 상호 간 비밀유지계약을 체결하고 그에 따라 철저히 고객 비밀을 보장하고 있습니다.",
  },
  {
    subject: "오류수정도 비용을 청구하나요?",
    content:
      "서비스/시스템 계약 시 요구사항을 정의하고 개발범위가 정해집니다. 기본적으로 개발범위에 해당하는 오류 사항들은 런칭 이후 3개월간 무상으로 보수를 시행하고 있지만, 계약된 개발범위 외에 추가로 발생하는 개발 요청 건에 대해서는 투입 시간별로 계산하여 월별로 청구되거나 추가 계약을 통해 개발이 진행됩니다.",
  },
  {
    subject: "결제사 계약, 앱스토어 출시, 서버 운영 등은 어떻게 진행 되나요?",
    content:
      "유피체인에서는 결제사 계약 신청 및 검수, 앱스토어 개발자 계정 등록, 앱스토어에 서비스/시스템 출시, 서버 호스팅 신청 등에 대해 가이드 해드립니다. 추후 고객사에서 직접 관리할 수 있도록 모든 고객사의 명의로 외부 서비스(예:서버호스팅, 도메인, 서드파티 등)를 등록할 수 있도록 지원하고 있으며 계약 종료 후에도 개발사에 종속됨 없이 서비스/시스템 독립성이 보장됩니다.",
  },
  {
    subject: "정부창업지원금으로 개발비를 집행하고 싶어요.",
    content:
      "정부창업지원금의 경우 개발비 지급 절차와 시점이 지원 프로그램마다 상이합니다. 개발비가 먼저 지급되거나 그에 상응하는 일정 금액의 개발비가 선입금되면 개발이 진행되기 때문에 지원 프로그램의 개발비 지급이 늦는 경우 먼저 고객사의 자체 자금으로 집행하셔야 합니다. 이후 지원을 통한 개발비가 입금되면 확인 후 자체 자금을 돌려드리고 있습니다.<br /><br />선급금이행보증, 계약이행보증, 과업지시서 등 준비가 필요한 서류 작업에 대해서는 유피체인에서 작업을 해드리고 있으며 해당 건은 무상으로 진행됩니다. 각각의 서류작업에 통상 몇 시간에서 며칠 정도의 시간이 소요되는 점 참고 바랍니다.",
  },
  {
    subject: "정부창업지원금과 자체 자금을 함께 사용하고 싶어요.",
    content:
      "정부창업지원사업에 선정되신 고객사의 정부지원금이 개발하려는 프로젝트의 퀄리티에 비해 부족할 때, 사업비와 자체 자금을 함께 사용하시려는 경우가 있습니다. 지원사업 개발비와 자체자금 개발비를 한 번에 투입하기보다는 정부지원사업 예산으로 구현할 수 있는 범위만 먼저 개발하고 완료보고까지 끝내어 지원사업 잔금이 처리된 후에 자체 자금을 추가 투입하는 것을 추천합니다.<br /><br />가령, 전체적으로 구현하고자 하는 기능이 A, B, C, D 이고 각각 천만 원의 개발 비용이 예상된다면, 정부지원 예산 내에서는 우선순위가 높은 A, B를 구현하겠다는 계획 및 견적, 계약을 하고 우선 구현을 집중하는 것입니다.<br /><br />정부지원사업이 끝나기 전에 자체 자금을 추가 투입하면 개발 예산의 성격이 중간에 섞여 혼란이 올 수 있으며 전체 구현 범위를 한꺼번에 구현하는 것은 프로젝트 안정성 측면에서 개발의 우선순위를 혼란스럽게 만들고 복잡도를 높입니다. 지원사업의 안정성 측면에서도 A, B가 끝나야 완료 보고를 안정적으로 통과하여 고객사도 무리 없이 지원사업을 종료할 수 있는데, 지원사업 계약서에는 빠져있는 C, D를 통시에 구현하다 보면 A, B의 구현이 늦어지거나 구현에 빠진 부분이 발생할 수 있어 지원사업의 성공적인 종료를 이끌어내기 힘들어지는 경우가 있습니다. 따라서 지원사업을 집행할 때는 지원사업의 예산을 모두 사용하여 완료 보고에 통과한 후에 자체 자금을 추가 집행하는 것이 좋습니다.",
  },
  {
    subject: "정부창업지원금의 개발비를 추가 집행하고 싶어요.",
    content:
      "정부창업지원사업의 개발비를 1차적으로 집행하여 개발을 진행하는 도중에, 지원금 예산을 조정하여 개발비에 추가 투입을 하고자 하실 때가 있습니다. 이런 경우, 기존 계약을 변경하기보다는 별도의 계약을 진행하여 계약서 양식과 일반 항목은 이전의 계약과 동일하게 하되 과업지시서와 개발비용, 기간 등만 변경하여 새로 작성하고 승인을 받으시면 됩니다. 다만, 이전에 개발이 진행 중이던 계약의 완료 보고가 안정적으로 끝날 수 있도록, 추가 계약 부분의 개발 진행은 이전 계약의 구현범위와 완료 보고까지 먼저 끝난 후에 개발을 시작하는 것이 좋습니다.",
  },
];
