import React from "react";
import { Tween } from "react-gsap";

const FadeInBottom = ({ children, ...rest }) => (
  <Tween
    from={{ autoAlpha: 0, y: "5vh" }}
    to={{ autoAlpha: 1, y: 0 }}
    duration={0.6}
    {...rest}
  >
    {children}
  </Tween>
);
export default FadeInBottom;
