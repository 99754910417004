/** @jsxImportSource @emotion/react */

import React from "react";
import { css } from "@emotion/react";
import { Tween } from "react-gsap";

function SlideUpText({
  children,
  repeat,
  threshold,
  trigger,
  delay,
  height,
  ...rest
}) {
  return (
    <Tween
      from={{
        yPercent: 120,
      }}
      wrapper={<div css={slideUpBox}></div>}
      delay={delay}
      ease="back.out(1.2)"
      duration={1}
      {...rest}
    >
      {children}
    </Tween>
  );
}
const slideUpBox = css`
  display: block;
  width: 100%;
  overflow: hidden;
`;
export default SlideUpText;
