/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from "react";
import { Container, Image } from "react-bootstrap";
import { images } from "@assets";

// animate
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { Reveal } from "react-gsap";
import FadeInBottom from "@components/FadeInBottom";
import SlideUpText from "@components/SlideUpText";

// image
import Wifi from "@assets/images/service/upwallet/wifi.svg";
import Laptop from "@assets/images/service/upwallet/laptop.svg";
import Phone from "@assets/images/service/upwallet/phone.svg";
import Coin from "@assets/images/service/upwallet/coin.svg";
import Light from "@assets/images/service/upwallet/light.svg";
import Figure from "@assets/images/service/upwallet/figure.svg";

const circleText = ["100%<br />콜드월렛", "파편화<br />암호화", "안정적<br />처리"];
export default React.memo(function UpWallet(props) {
  const [activeIndex, setActiveIndex] = useState(0);

  const visualRef = useRef();
  const pinWrapRef = useRef();
  const keywordRef = useRef();

  // ----------------------------------------------------------------------------------------------
  // ----------------------------------------------------------------------------------------------
  useEffect(() => {
    // animate cover panel
    ScrollTrigger.create({
      id: "animateCoverPanel",
      trigger: visualRef.current,
      start: "top top",
      end: "bottom top",
      pin: true,
      pinSpacing: false,
    });

    // animate highlight words
    const highlightWords = gsap.utils.toArray(".highlight");
    highlightWords.forEach((word, i) => {
      ScrollTrigger.create({
        id: `animateHighlightWords${i}`,
        trigger: word,
        start: "200px bottom",
        endtrigger: ".section01",
        toggleClass: "active",
      });
    });

    // animate contents change
    const changePanels = gsap.utils.toArray(".change-panel");
    let currentPanel = changePanels[0];
    const setPanel = (newPanel, i) => {
      gsap.to(currentPanel, {
        y: -60,
        opacity: 0,
      });
      gsap.to(newPanel, {
        y: 0,
        opacity: 1,
        duration: 0.3,
      });
      currentPanel = newPanel;
      setActiveIndex(i);
    };

    let pinWrapTop = window.pageYOffset + pinWrapRef.current.getBoundingClientRect().top;
    gsap.defaults({ overwrite: "auto", duration: 0.3 });

    ScrollTrigger.create({
      id: "pinChangeContents",
      trigger: pinWrapRef.current,
      start: "top top",
      end: "bottom bottom",
      pin: true,
      pinSpacing: 0,
      onLeave: () => pinWrapRef.current.classList.add("leave"),
      onEnterBack: () => pinWrapRef.current.classList.remove("leave"),
      // markers: true,
    });

    changePanels.forEach((panel, i) => {
      ScrollTrigger.create({
        id: `animateChangeContents${i}`,
        trigger: panel,
        start: () => (props.isSmallScreen ? pinWrapTop + (i - 0.5) * (window.innerHeight * 0.8) : pinWrapTop + (i - 0.5) * (window.innerHeight * 0.6)),
        end: () => (props.isSmallScreen ? pinWrapTop + (i + 0.5) * (window.innerHeight * 0.8) : pinWrapTop + (i + 0.5) * (window.innerHeight * 0.6)),
        onToggle: (self) => self.isActive && setPanel(panel, i),
        toggleActions: "play pause resume reset",
        // markers: true,
      });
    });

    // kill
    return () => {
      // animate cover panel
      if (ScrollTrigger.getById("animateCoverPanel") !== undefined) {
        ScrollTrigger.getById("animateCoverPanel").kill();
      }

      // animate highlight words
      if (highlightWords.length > 0 && ScrollTrigger.getById("animateHighlightWords0") !== undefined) {
        for (let i in highlightWords) {
          ScrollTrigger.getById(`animateHighlightWords${i}`).kill();
        }
      }

      // animate contents change
      if (ScrollTrigger.getById("pinChangeContents") !== undefined) {
        ScrollTrigger.getById("pinChangeContents").kill();
      }
      if (changePanels.length > 0 && ScrollTrigger.getById("animateChangeContents0") !== undefined) {
        for (let i in changePanels) {
          ScrollTrigger.getById(`animateChangeContents${i}`).kill();
        }
      }
    };
  }, []);

  return (
    <main id="svc-wallet" className="service">
      <div className="panel visual" ref={visualRef}>
        <section className="parallax-bg" style={{ backgroundImage: `url(${images.service.upwallet.visual})` }}>
          <Container>
            <div className="typo">
              <SlideUpText delay="0.2">
                <h1 className="title-bold-03">UP WALLET</h1>
              </SlideUpText>
              <SlideUpText delay="0.8">
                <p className="sub-title-white">개인키를 분리한 후{props.isSmallScreen ? <br /> : " "}다시 암호화 및 분산 저장하는 지갑서비스</p>
              </SlideUpText>
            </div>
          </Container>
        </section>
      </div>

      <div className="panel bottom">
        {/* SECTION-01 */}
        <section className="section01">
          <Container>
            <Reveal>
              <FadeInBottom>
                <div className="typo">
                  <h2 className="title-bold-02">기존 암호화폐 지갑은 안전한가요?</h2>
                  <p>
                    전자지갑은 보안에 안전하다고 알려졌지만 잦은 해킹사고로 취약성이 발견되었고,{!props.isSmallScreen ? <br /> : " "}
                    이를 보완한 더 안전한 암호화폐 지갑들이 개발되고 있습니다.{" "}
                  </p>
                </div>
              </FadeInBottom>
            </Reveal>
            <Reveal threshold={0.2}>
              <FadeInBottom>
                <ul className="words">
                  <li>
                    <span>투자</span>
                    <span>하드웨어</span>
                    <span>보안</span>
                    <span className="highlight">단일저장</span>
                    <span>기술적 결함</span>
                  </li>
                  <li>
                    <span>공용PC</span>
                    <span>구매비용</span>
                    <span>다중서명</span>
                    <span>거래 세금</span>
                    <span>자금세탁</span>
                  </li>
                  <li>
                    <span>기기파쇄</span>
                    <span className="highlight">내부보안</span>
                    <span>도난</span>
                    <span>사용성</span>
                    <span>해킹</span>
                    <span>수수료</span>
                  </li>
                  <li>
                    <span>실시간 거래</span>
                    <span>분실</span>
                    <span>매개체 보안성</span>
                    <span>실명확인</span>
                  </li>
                </ul>
              </FadeInBottom>
            </Reveal>
          </Container>
        </section>

        {/* SECTION-02 */}
        <section className="section02">
          <Container>
            <div className="change-panel-wrap" ref={pinWrapRef}>
              <div className="typo">
                <h2 className="title-bold-02">UP WALLET은 어떻게 다를까요?</h2>
                <p>
                  UP WALLET은 기존 암호화폐 지갑방식에서 탈피하여
                  {!props.isSmallScreen ? <br /> : " "}암호키를 파편화/암호화/분산저장하는 시스템으로 더욱 강화된 보안체계 구조를 보유하고 있습니다.
                </p>
              </div>

              {/* pin */}
              <div className="change-panel-list">
                <article className="change-panel change-01">
                  <Image src={images.service.upwallet.wallet} alt="콜드월렛" />
                  <p>
                    기존의 암호화폐 지갑 서버(핫월렛/콜드월렛)
                    {props.isSmallScreen ? <br /> : " "}개념을 탈피한 100% 콜드월렛
                  </p>
                </article>
                <article className="change-panel change-02">
                  <Image src={images.service.upwallet.security} alt="보안성" />
                  <p>
                    블록체인 노드에서 분리한 암호키를
                    {props.isSmallScreen ? <br /> : " "}
                    파편화/암호화/분산저장하여 더욱 강화된 보안체계
                  </p>
                </article>
                <article className="change-panel change-03">
                  <Image src={images.service.upwallet.stable} alt="안정성" />
                  <p>
                    실제 여러 거래소에서 운영중인 UP WALLET은
                    {props.isSmallScreen ? <br /> : " "}10억 건 이상의 대용량 트랜잭션을 안정적으로 처리
                  </p>
                </article>
              </div>
              {/* //pin */}

              <div className="keywords" ref={keywordRef}>
                {circleText.length > 0 &&
                  circleText.map((text, index) => {
                    return <div key={`keyword_${index}`} className={activeIndex === index ? "active" : null} dangerouslySetInnerHTML={{ __html: text }}></div>;
                  })}
              </div>
            </div>
          </Container>
        </section>

        {/* SECTION-03 */}
        <section className="section03">
          <div className="walletBg"></div>
          <div className="content">
            <Container>
              <Reveal>
                <FadeInBottom>
                  <h2 className="title-bold-02 text-white">UP WALLET 구조</h2>
                </FadeInBottom>
              </Reveal>
              <Reveal threshold={0.4}>
                <FadeInBottom>
                  <div className="structure">
                    <Wifi />
                    <Laptop />
                    <Phone />
                    <Coin />
                    <Image src={images.service.upwallet.structure01} alt="WALLET 구조1" />
                  </div>
                </FadeInBottom>
              </Reveal>
              <Reveal threshold={0.4}>
                <FadeInBottom>
                  <div className="structure">
                    <Figure />
                    <Light />
                    <Image src={images.service.upwallet.structure02} alt="WALLET 구조2" />
                  </div>
                </FadeInBottom>
              </Reveal>
            </Container>
          </div>
        </section>

        {/* SECTION-04 */}
        <section className="section04">
          <Container>
            <Reveal>
              <FadeInBottom>
                <div className="typo">
                  <h2 className="title-bold-02">다양한 블록체인 Node 경험</h2>
                  <p>
                    시스템 및 서비스 운영에 필요한 다양한 블록체인 계열에 Node 경험을 바탕으로{!props.isSmallScreen ? <br /> : " "}
                    Main-Net, 지갑개발, 토큰개발등 시스템 및 서비스 방향에 맞춰 설계 개발을 해드립니다.
                  </p>
                </div>
              </FadeInBottom>
            </Reveal>
            <Reveal threshold={0.2}>
              <FadeInBottom>
                <Image src={images.service.upwallet.node} alt="다양한 블록체인 Node 경험" />
              </FadeInBottom>
            </Reveal>
          </Container>
        </section>
      </div>
    </main>
  );
});
