/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useLayoutEffect, lazy, Suspense, useRef } from "react";
import { Container, ListGroup, Form, Row, Spinner } from "react-bootstrap";
import { firebase_db } from "../../firebaseConfig";

// animate
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { Reveal } from "react-gsap";
import FadeInBottom from "@components/FadeInBottom";

const LazyItem = lazy(() => import("./components/ListItem"));

export default React.memo(function WorksList(props) {
  const [worksList, setWorksList] = useState([]);
  const [tabKey, setTabKey] = useState("all");
  const isInitialMount = useRef(true);

  const handleTabKey = (e) => {
    // console.log(e.target.value)
    setTabKey(e.target.value);
  };

  // ----------------------------------------------------------------------------------------------
  // ----------------------------------------------------------------------------------------------
  // works data
  useLayoutEffect(() => {
    const dbRef = firebase_db.ref("/works");
    dbRef.once("value").then((snapshot) => {
      let data = snapshot.val();
      setWorksList(data);
    });
    return () => {
      dbRef.off();
      if (ScrollTrigger.getById("batchAnimate") !== undefined) {
        ScrollTrigger.getAll("batchAnimate").forEach((st) => st.kill());
      }
    };
  }, []);

  // batch animate
  useEffect(() => {
    const setTimeoutBatch = setTimeout(() => {
      ScrollTrigger.batch(".works-item", {
        id: "batchAnimate",
        batchMax: props.isSmallScreen ? 2 : 3,
        onEnter: (batch) => gsap.to(batch, { opacity: 1, top: 0, duration: 0.2, stagger: 0.2 }),
      });
    }, 500);
    return () => {
      clearTimeout(setTimeoutBatch);
    };
  }, [worksList]);

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
    } else {
      // console.log("useEffect22");
      // console.log(ScrollTrigger.getAll());
      ScrollTrigger.batch(".works-item:not(.hide)", {
        id: "batchAnimate",
        batchMax: props.isSmallScreen ? 2 : 3,
        onEnter: (batch) => gsap.to(batch, { opacity: 1, top: 0, duration: 0.2, stagger: 0.2 }),
      });
    }
    return () => {
      if (ScrollTrigger.getById("batchAnimate") !== undefined) {
        ScrollTrigger.refresh();
        gsap.set(".works-item", { top: 20, opacity: 0 });
      }
    };
  }, [tabKey]);

  return (
    <main id="works-list" className="noVisual">
      <Container>
        <div className="title-area">
          <Reveal>
            <FadeInBottom>
              <h1 className="title-bold-03">Works</h1>
              <div className="nav-list">
                <Form.Check label="ALL" name="tabKey" type="radio" id="all" value="all" onChange={handleTabKey} checked={tabKey === "all"} />
                <Form.Check label="블록체인" name="tabKey" type="radio" id="bc" value="bc" onChange={handleTabKey} checked={tabKey === "bc"} />
                <Form.Check label="인공지능 (AI)" name="tabKey" type="radio" id="ai" value="ai" onChange={handleTabKey} checked={tabKey === "ai"} />
                <Form.Check label="E-커머스" name="tabKey" type="radio" id="ec" value="ec" onChange={handleTabKey} checked={tabKey === "ec"} />
                <Form.Check label="ICT 기술융합" name="tabKey" type="radio" id="ict" value="ict" onChange={handleTabKey} checked={tabKey === "ict"} />
              </div>
            </FadeInBottom>
          </Reveal>
        </div>
        <ListGroup variant="flush" as="ul" className="row">
          {worksList.length > 0 ? (
            worksList.map((work, index) => {
              return (
                <Suspense fallback={<div></div>} key={`work-${index}`}>
                  <LazyItem
                    index={index}
                    // id={work.id}
                    // title={work.title}
                    // primary={work.primary}
                    // thumb={work.thumb}
                    // tabArr={work.tab}
                    data={work}
                    show={tabKey}
                  />
                </Suspense>
              );
            })
          ) : (
            <Reveal>
              <FadeInBottom>
                <Row className="spinner-inner no-fixed">
                  <Spinner animation="border" variant="primary" />
                </Row>
              </FadeInBottom>
            </Reveal>
          )}
        </ListGroup>
      </Container>
    </main>
  );
});
