// HTML 태그 제거
export const removeHTML = (text) => {
  var img_tag = /<IMG(.*?)>/gi;
  let result = text
    .replace(img_tag, "")
    .replace(/<(\/)?([a-zA-Z]*)(\s[a-zA-Z]*=[^>]*)?(\s)*(\/)?>/gi, " ");
  return result;
};

// 날짜 하이픈 추가 (숫자만 입력)
export const dateHypenFormat = (date) => {
  let result = date.trim();
  result = result
    .replace(/[^0-9]/g, "")
    .replace(/(\d{4})(\d{2})?(\d{2})/, "$1-$2-$3")
    .replace("--", "-");
  return result;
};

// 날짜 닷 추가 (숫자만 입력)
export const dateDotFormat = (date) => {
  let result = date.trim();
  result = result
    .replace(/[^0-9]/g, "")
    .replace(/(\d{4})(\d{2})?(\d{2})/, "$1.$2.$3")
    .replace("..", ".");
  return result;
};

// 날짜 년/월/일 텍스트 추가 (숫자만 입력)
export const dateTextFormat = (date) => {
  let result = date.trim();
  result = result
    .replace(/[^0-9]/g, "")
    .replace(/(\d{4})(\d{2})?(\d{2})/, "$1년 $2월 $3일");
  return result;
};

// 한글, 영어만 가능
export const charTest = (char) => {
  let reg = /^[가-힣a-zA-Z\s]+$/;
  return reg.test(char) ? true : false;
};

// 이메일 유효성 검사
export const emailTest = (email) => {
  let reg =
    /^[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,3}$/i; // eslint-disable-line
  return reg.test(email) ? true : false;
};

// 모든 전화번호 유효성 검사
export const phoneNumberTest = (phoneNumber) => {
  let removeHyphen = phoneNumber.replace(/\-/g, ""); // eslint-disable-line
  let reg = /^(01[016789]{1}|02|0[3-9]{1}[0-9]{1})?[0-9]{3,4}?[0-9]{4}$/;
  return reg.test(removeHyphen) ? true : false;
};
