/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useLayoutEffect, useState, useRef } from "react";
import { useMediaQuery } from "react-responsive";
import { Container, Image } from "react-bootstrap";
import { images } from "@assets";
import classnames from "classnames";

// animate
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { Reveal } from "react-gsap";
import FadeInBottom from "@components/FadeInBottom";
import SlideUpText from "@components/SlideUpText";

// swiper
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation } from "swiper";
import "swiper/swiper.scss";
import "swiper/components/navigation/navigation.scss";

SwiperCore.use([Navigation]);

export default React.memo(function T3(props) {
  const isMobileScreen = useMediaQuery({ maxWidth: 640 });

  const visualRef = useRef();
  const pinWrapRef01 = useRef();
  const pinWrapRef02 = useRef();
  const [activeIndex, setActiveIndex] = useState(0);

  const swiperPrevRef = useRef(null);
  const swiperNextRef = useRef(null);

  // ----------------------------------------------------------------------------------------------
  // ----------------------------------------------------------------------------------------------
  // kill
  useLayoutEffect(() => {
    return () => {
      // animate cover panel
      if (ScrollTrigger.getById("animateCoverPanel") !== undefined) {
        ScrollTrigger.getById("animateCoverPanel").kill();
      }

      // animate contents change - UP T3는 고객의 다양한 요구에 맞추어 준비하고 있습니다.
      if (ScrollTrigger.getById("pinChangeContents01") !== undefined) {
        ScrollTrigger.getById("pinChangeContents01").kill();
        // console.log("kill01");
      }

      // animate contents change - UP T3 적용분야
      if (ScrollTrigger.getById("pinChangeContents02") !== undefined) {
        ScrollTrigger.getById("pinChangeContents02").kill();
        // console.log("kill02");
      }
    };
  }, []);

  // animate cover panel
  useEffect(() => {
    ScrollTrigger.create({
      id: "animateCoverPanel",
      trigger: visualRef.current,
      start: "top top",
      end: "bottom top",
      pin: true,
      pinSpacing: false,
    });

    // animate contents change - UP T3는 고객의 다양한 요구에 맞추어 준비하고 있습니다.
    const changePanels01 = gsap.utils.toArray(".change-panel-01");
    let currentPanel01 = changePanels01[0];
    const setPanel01 = (newPanel) => {
      gsap.to(currentPanel01, {
        y: props.isSmallScreen ? -20 : -60,
        opacity: 0,
        zIndex: 0,
      });
      gsap.to(newPanel, {
        y: 0,
        opacity: 1,
        zIndex: 1,
        duration: 0.3,
      });
      currentPanel01 = newPanel;
    };

    let pinWrapTop01 = window.pageYOffset + pinWrapRef01.current.getBoundingClientRect().top;
    gsap.defaults({ overwrite: "auto", duration: 0.3 });

    ScrollTrigger.create({
      id: "pinChangeContents01",
      trigger: pinWrapRef01.current,
      start: "top top",
      end: "bottom bottom",
      pin: true,
      pinSpacing: 0,
      onLeave: () => pinWrapRef01.current.classList.add("leave"),
      onEnterBack: () => {
        pinWrapRef01.current.classList.remove("leave");
        // console.log("leave가 없어져요 01");
      },
      // markers: true,
    });

    changePanels01.forEach((panel, i) => {
      ScrollTrigger.create({
        id: `animateChangeContents01-${i}`,
        trigger: panel,
        start: () => (props.isSmallScreen ? pinWrapTop01 + (i - 0.5) * (window.innerHeight * 0.8) : pinWrapTop01 + (i - 0.5) * (window.innerHeight * 0.6)),
        end: () => (props.isSmallScreen ? pinWrapTop01 + (i + 0.5) * (window.innerHeight * 0.8) : pinWrapTop01 + (i + 0.5) * (window.innerHeight * 0.6)),
        onToggle: (self) => self.isActive && setPanel01(panel, i),
        toggleActions: "play pause resume reset",
        // markers: true,
      });
    });

    // animate contents change - UP T3 적용분야
    const changePanels02 = gsap.utils.toArray(".change-panel-02");
    let currentPanel02 = changePanels02[0];
    const setPanel02 = (newPanel, i) => {
      gsap.to(currentPanel02, {
        opacity: 0,
        zIndex: 0,
      });
      gsap.to(newPanel, {
        opacity: 1,
        zIndex: 1,
        duration: 0.3,
      });
      currentPanel02 = newPanel;
      setActiveIndex(i);
    };

    let pinWrapTop02 = window.pageYOffset + pinWrapRef02.current.getBoundingClientRect().top;
    gsap.defaults({ overwrite: "auto", duration: 0.3 });

    ScrollTrigger.create({
      id: "pinChangeContents02",
      trigger: pinWrapRef02.current,
      start: "top top",
      end: "bottom bottom",
      pin: true,
      pinSpacing: 0,
      onLeave: () => pinWrapRef02.current.classList.add("leave"),
      onEnterBack: () => {
        pinWrapRef02.current.classList.remove("leave");
        // console.log("leave가 없어져요 02");
      },
      // markers: true,
    });

    changePanels02.forEach((panel, i) => {
      ScrollTrigger.create({
        id: `animateChangeContents02-${i}`,
        trigger: panel,
        start: () => (props.isSmallScreen ? pinWrapTop02 + (i - 0.5) * (window.innerHeight * 0.8) : pinWrapTop02 + (i - 0.5) * (window.innerHeight * 0.6)),
        end: () => (props.isSmallScreen ? pinWrapTop02 + (i + 0.5) * (window.innerHeight * 0.8) : pinWrapTop02 + (i + 0.5) * (window.innerHeight * 0.6)),
        onToggle: (self) => self.isActive && setPanel02(panel, i),
        toggleActions: "play pause resume reset",
        // markers: true,
      });
    });

    // kill
    return () => {
      // animate contents change - UP T3는 고객의 다양한 요구에 맞추어 준비하고 있습니다.
      if (changePanels01.length > 0 && ScrollTrigger.getById("animateChangeContents01-0") !== undefined) {
        for (let i in changePanels01) {
          ScrollTrigger.getById(`animateChangeContents01-${i}`).kill();
          // console.log(`kill01-${i}`);
        }
      }

      // animate contents change - UP T3 적용분야
      if (changePanels02.length > 0 && ScrollTrigger.getById("animateChangeContents02-0") !== undefined) {
        for (let i in changePanels02) {
          ScrollTrigger.getById(`animateChangeContents02-${i}`).kill();
          // console.log(`kill02-${i}`);
        }
      }
    };
  }, []);

  return (
    <main id="svc-t3" className="service">
      <div className="panel visual" ref={visualRef}>
        <section
          className="parallax-bg"
          style={{
            backgroundImage: `url(${
              props.isSmallScreen ? (isMobileScreen ? images.service.upt3.visualMobile : images.service.upt3.visualTablet) : images.service.upt3.visual
            })`,
          }}
        >
          <Container>
            <div className="typo">
              <SlideUpText delay="0.2">
                <h1 className="title-bold-03">UP T3</h1>
              </SlideUpText>
              <SlideUpText delay="0.3">
                <h1 className="title-bold-03 fullname">(Trust Transparent Things)</h1>
              </SlideUpText>
              <SlideUpText delay="0.8">
                <h2 className="title-bold-02">IoT에 특화된 마이크로 컴퓨터용 블록체인</h2>
              </SlideUpText>
              <SlideUpText delay="1.4">
                <p className="sub-title-white" style={{ fontSize: props.isSmallScreen ? "1rem" : "1.5rem", padding: props.isSmallScreen && "0 1rem" }}>
                  블록체인 기반 사물인터넷인(BIoT)로, 데이터 노드 별로 분산 저장하기 때문에{!props.isSmallScreen ? <br /> : " "}
                  자체 경량화한 DAG블록체인을 사용하여,라즈베리파이에서도 블록체인 서버를
                  {!props.isSmallScreen ? <br /> : " "}
                  돌릴 수 있도록 하였다는데에 큰 장점이 있습니다.
                </p>
              </SlideUpText>
            </div>
          </Container>
        </section>
      </div>

      <div className="panel bottom">
        {/* UP T3는 고객의 다양한 요구에 맞추어 준비하고 있습니다. */}
        <section className="section01">
          <Container>
            <div className="change-panel-wrap" ref={pinWrapRef01}>
              <div className="typo">
                <h2 className="title-bold-02 text-center">UP T3는 고객의 다양한 요구에 맞추어{props.isSmallScreen ? <br /> : " "}준비하고 있습니다.</h2>
              </div>
              {/* pin */}
              <div className="change-panel-list">
                <article className="change-panel-01 change-01">
                  <div className="align-items-start">
                    <h3>약 200g의 초소형 블록체인 기기</h3>
                    <p>
                      기존 블록체인을 경양화, 효율화하여 IoT에서 오는 정보를 곧바로 블록체인화 합니다. 고성능 블록체인 서버에서 직접 통신하던 방식과 달리 서버와
                      통신이 매우 열악한 상황에서도 운영이 가능합니다.
                    </p>
                  </div>
                  <div className="img-box">
                    <Image src={images.service.upt3.small} alt="약 200g의 초소형 블록체인 기기" />
                  </div>
                  <div className="strong-text">
                    <strong>200g</strong>
                  </div>
                </article>
                <article className="change-panel-01 change-02">
                  <div className="align-items-start">
                    <h3>6개월 이상 배터리 지속</h3>
                    <p>초저전력 센서기기와 초소형 블록체인 기기를 사용하기 때문에 비교적 저렴한 도입비용 및 운용비용으로 경제성에 있어 탁월합니다.</p>
                  </div>
                  <div className="img-box">
                    <Image src={images.service.upt3.battery} alt="6개월 이상 배터리 지속" />
                  </div>
                  <div className="strong-text">
                    <strong>6 Month</strong>
                  </div>
                </article>
                <article className="change-panel-01 change-03">
                  <div className="align-items-start">
                    <h3>초당 500TPS 이상의 대량 트랜잭션</h3>
                    <p>
                      즉각 지속적으로 데이터 기록을 하며 열악한 통신과 컴퓨팅 환경에서도 대량 트랜잭션이 가능해 산업 현장에 적합한 산업 IoT 블록체인 입니다.
                    </p>
                  </div>
                  <div className="img-box">
                    <Image src={images.service.upt3.ts} alt="초당 500TPS 이상의 대량 트랜잭션" />
                  </div>
                  <div className="strong-text">
                    <strong>500 TPS</strong>
                  </div>
                </article>
              </div>
              {/* //pin */}
            </div>
          </Container>
        </section>

        {/* 도입효과 */}
        <section className="section02">
          <Container>
            <Reveal>
              <FadeInBottom>
                <div className="typo">
                  <h2 className="title-bold-02">UP T3 도입효과</h2>
                </div>
              </FadeInBottom>
            </Reveal>
            <div className="swiper-wrap">
              <div ref={swiperPrevRef} className="swiper-button-prev"></div>
              <div ref={swiperNextRef} className="swiper-button-next"></div>
              <Swiper
                slidesPerView={1.5}
                spaceBetween={30}
                className="swiper-t3"
                breakpoints={{
                  640: {
                    slidesPerView: 2.2,
                    spaceBetween: 16,
                  },
                  1024: {
                    slidesPerView: 3.5,
                    spaceBetween: 16,
                  },
                }}
                navigation={{
                  prevEl: swiperPrevRef.current,
                  nextEl: swiperNextRef.current,
                }}
                onInit={(swiper) => {
                  swiper.params.navigation.prevEl = swiperPrevRef.current;
                  swiper.params.navigation.nextEl = swiperNextRef.current;
                  swiper.navigation.update();
                }}
                modules={[Navigation]}
              >
                <SwiperSlide>
                  <div className="img-box">
                    <Image src={images.service.upt3.eft01} />
                  </div>
                  <h3>데이터 관리</h3>
                  <p>마이크로 컴퓨터와 IoT 센서의 결합을 통해 즉각적 · 지속적으로 데이터 기록 관리를 할 수 있습니다.</p>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="img-box">
                    <Image src={images.service.upt3.eft02} />
                  </div>
                  <h3>데이터의 안전 거래</h3>
                  <p>환경과 사람의 개입 없이 안전하고 투명한 데이터를 축적 할 수 있습니다.</p>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="img-box">
                    <Image src={images.service.upt3.eft03} />
                  </div>
                  <h3>블록체인 인증키</h3>
                  <p>중앙 블록체인을 통해 수신된 데이터를 실시간으로 모니터링하여 신뢰도 상승 효과가 있습니다.</p>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="img-box">
                    <Image src={images.service.upt3.eft04} />
                  </div>
                  <h3>고부가가치 생산품 관리</h3>
                  <p>산업현장에서 실질적으로 활용 가능한 높은 확장성을 가질 수 있습니다.</p>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="img-box">
                    <Image src={images.service.upt3.eft05} />
                  </div>
                  <h3>중요 시설물 관리</h3>
                  <p>장거리에서도 안정적인 데이터 송수신이 가능하여 중요 시설물 관리에 용이합니다.</p>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="img-box">
                    <Image src={images.service.upt3.eft06} />
                  </div>
                  <h3>DAG 블록체인</h3>
                  <p>10배이상 빠른 처리 속도와 완성도가 높고 간편한 내장 시스템을 갖출 수 있습니다.</p>
                </SwiperSlide>
              </Swiper>
            </div>
          </Container>
        </section>

        {/* 적용분야 */}
        <section className="section03">
          <Container>
            <div className="change-panel-wrap" ref={pinWrapRef02}>
              <div className="typo">
                <h2 className="title-bold-02">UP T3 적용분야</h2>
              </div>
              {/* pin */}
              <div className="change-panel-list">
                <article className="change-panel-02 change-01">
                  <div>
                    <h3>물류 운송</h3>
                    <strong>수산, 철도, 해운, 항공, 화물</strong>
                    <p>
                      계약에서부터 운송, 통관, 보관을 거쳐 제품을 최종 소비자에게 전달하기까지의 복잡한 단계를 블록체인으로 추적하고 효울적으로 관리 가능합니다.
                    </p>
                  </div>
                </article>
                <article className="change-panel-02 change-02">
                  <div>
                    <h3>안전</h3>
                    <strong>환경, 보안, 교통</strong>
                    <p>
                      데이터 기록 관리에 도움을 주어 보안이 철저하며 교통 흐름을 통제 관리할 수 있습니다. 또한 에너지생산 관리, 보안 등 인프라를 구축하는 일에
                      효과적으로 사용될 수 있습니다.
                    </p>
                  </div>
                </article>
                <article className="change-panel-02 change-03">
                  <div>
                    <h3>스마트 팩토리</h3>
                    <strong>자동화, 생산관리</strong>
                    <p>생산정보자동화, 품질정보, 설비 인터페이스 등 데이터를 보안 관리하며 생산품에 대한 피해손실을 막을 수 있습니다.</p>
                  </div>
                </article>
                <div className="change-panel-02-box">
                  <div className={classnames("box-01", { active: activeIndex === 0 })}>
                    <figure style={{ backgroundImage: `url(${activeIndex === 0 ? images.service.upt3.applyActive01 : images.service.upt3.apply01})` }}></figure>
                    <figure style={{ backgroundImage: `url(${activeIndex === 0 ? images.service.upt3.applyActive02 : images.service.upt3.apply02})` }}></figure>
                  </div>
                  <div className={classnames("box-02", { active: activeIndex === 1 })}>
                    <figure style={{ backgroundImage: `url(${activeIndex === 1 ? images.service.upt3.applyActive03 : images.service.upt3.apply03})` }}></figure>
                    <figure style={{ backgroundImage: `url(${activeIndex === 1 ? images.service.upt3.applyActive04 : images.service.upt3.apply04})` }}></figure>
                  </div>
                  <div className={classnames("box-03", { active: activeIndex === 2 })}>
                    <figure style={{ backgroundImage: `url(${activeIndex === 2 ? images.service.upt3.applyActive05 : images.service.upt3.apply05})` }}></figure>
                    <figure style={{ backgroundImage: `url(${activeIndex === 2 ? images.service.upt3.applyActive06 : images.service.upt3.apply06})` }}></figure>
                  </div>
                </div>
              </div>
              {/* //pin */}
            </div>
          </Container>
        </section>

        {/* 적용사례 */}
        <section className="section04">
          <Container>
            <Reveal>
              <FadeInBottom>
                <div className="typo">
                  <h2 className="title-bold-02">UP T3 적용사례</h2>
                </div>
              </FadeInBottom>
            </Reveal>
            <div className="mt-4">
              <video src="/assets/videos/upt3.mp4" loop autoPlay muted controls playsInline />
            </div>
          </Container>
        </section>
      </div>
    </main>
  );
});
