/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useLayoutEffect, useState, useRef } from "react";
import { Container, Image } from "react-bootstrap";
import { images } from "@assets";

// animate
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { Reveal } from "react-gsap";
import FadeInBottom from "@components/FadeInBottom";
import SlideUpText from "@components/SlideUpText";

export default React.memo(function Hmps(props) {
  const visualRef = useRef();
  const pinWrapRef01 = useRef();
  const pinWrapRef02 = useRef();
  const [activeIndex01, setActiveIndex01] = useState(0);
  const [activeIndex02, setActiveIndex02] = useState(0);

  // ----------------------------------------------------------------------------------------------
  // ----------------------------------------------------------------------------------------------
  // kill
  useLayoutEffect(() => {
    return () => {
      // animate cover panel
      if (ScrollTrigger.getById("animateCoverPanel") !== undefined) {
        ScrollTrigger.getById("animateCoverPanel").kill();
      }

      // animate contents change - MICE Platform
      if (ScrollTrigger.getById("pinChangeContents01") !== undefined) {
        ScrollTrigger.getById("pinChangeContents01").kill();
        // console.log("kill01");
      }

      // animate contents change - MICE Platform ADMIN

      if (ScrollTrigger.getById("pinChangeContents02") !== undefined) {
        ScrollTrigger.getById("pinChangeContents02").kill();
        // console.log("kill02");
      }
    };
  }, []);

  // animate cover panel
  useEffect(() => {
    ScrollTrigger.create({
      id: "animateCoverPanel",
      trigger: visualRef.current,
      start: "top top",
      end: "bottom top",
      pin: true,
      pinSpacing: false,
    });

    // animate contents change - MICE Platform
    const changePanels01 = gsap.utils.toArray(".change-panel-01");
    let currentPanel01 = changePanels01[0];
    const setPanel01 = (newPanel, i) => {
      gsap.to(currentPanel01, {
        y: props.isSmallScreen ? -20 : -60,
        opacity: 0,
        zIndex: 0,
      });
      gsap.to(newPanel, {
        y: 0,
        opacity: 1,
        zIndex: 1,
        duration: 0.3,
      });
      currentPanel01 = newPanel;
      setActiveIndex01(i);
    };

    let pinWrapTop01 = window.pageYOffset + pinWrapRef01.current.getBoundingClientRect().top;
    gsap.defaults({ overwrite: "auto", duration: 0.3 });

    ScrollTrigger.create({
      id: "pinChangeContents01",
      trigger: pinWrapRef01.current,
      start: "top top",
      end: "bottom bottom",
      pin: true,
      pinSpacing: 0,
      onLeave: () => pinWrapRef01.current.classList.add("leave"),
      onEnterBack: () => {
        pinWrapRef01.current.classList.remove("leave");
        // console.log("leave가 없어져요 01");
      },
      // markers: true,
    });

    changePanels01.forEach((panel, i) => {
      ScrollTrigger.create({
        id: `animateChangeContents01-${i}`,
        trigger: panel,
        start: () => (props.isSmallScreen ? pinWrapTop01 + (i - 0.5) * (window.innerHeight * 0.8) : pinWrapTop01 + (i - 0.5) * (window.innerHeight * 0.6)),
        end: () => (props.isSmallScreen ? pinWrapTop01 + (i + 0.5) * (window.innerHeight * 0.8) : pinWrapTop01 + (i + 0.5) * (window.innerHeight * 0.6)),
        onToggle: (self) => self.isActive && setPanel01(panel, i),
        toggleActions: "play pause resume reset",
        // markers: true,
      });
    });

    // animate contents change - MICE Platform ADMIN
    const changePanels02 = gsap.utils.toArray(".change-panel-02");
    let currentPanel02 = changePanels02[0];
    const setPanel02 = (newPanel, i) => {
      gsap.to(currentPanel02, {
        y: props.isSmallScreen ? -20 : -60,
        opacity: 0,
        zIndex: 0,
      });
      gsap.to(newPanel, {
        y: 0,
        opacity: 1,
        zIndex: 1,
        duration: 0.3,
      });
      currentPanel02 = newPanel;
      setActiveIndex02(i);
    };

    let pinWrapTop02 = window.pageYOffset + pinWrapRef02.current.getBoundingClientRect().top;
    gsap.defaults({ overwrite: "auto", duration: 0.3 });

    ScrollTrigger.create({
      id: "pinChangeContents02",
      trigger: pinWrapRef02.current,
      start: "top top",
      end: "bottom bottom",
      pin: true,
      pinSpacing: 0,
      onLeave: () => pinWrapRef02.current.classList.add("leave"),
      onEnterBack: () => {
        pinWrapRef02.current.classList.remove("leave");
        // console.log("leave가 없어져요 02");
      },
      // markers: true,
    });

    changePanels02.forEach((panel, i) => {
      ScrollTrigger.create({
        id: `animateChangeContents02-${i}`,
        trigger: panel,
        start: () => (props.isSmallScreen ? pinWrapTop02 + (i - 0.5) * (window.innerHeight * 0.8) : pinWrapTop02 + (i - 0.5) * (window.innerHeight * 0.6)),
        end: () => (props.isSmallScreen ? pinWrapTop02 + (i + 0.5) * (window.innerHeight * 0.8) : pinWrapTop02 + (i + 0.5) * (window.innerHeight * 0.6)),
        onToggle: (self) => self.isActive && setPanel02(panel, i),
        toggleActions: "play pause resume reset",
        // markers: true,
      });
    });

    // kill
    return () => {
      // animate contents change - MICE Platform
      if (changePanels01.length > 0 && ScrollTrigger.getById("animateChangeContents01-0") !== undefined) {
        for (let i in changePanels01) {
          ScrollTrigger.getById(`animateChangeContents01-${i}`).kill();
          // console.log(`kill01-${i}`);
        }
      }

      // animate contents change - MICE Platform ADMIN
      if (changePanels02.length > 0 && ScrollTrigger.getById("animateChangeContents02-0") !== undefined) {
        for (let i in changePanels02) {
          ScrollTrigger.getById(`animateChangeContents02-${i}`).kill();
          // console.log(`kill02-${i}`);
        }
      }
    };
  }, []);

  return (
    <main id="svc-hmps" className="service">
      <div className="panel visual" ref={visualRef}>
        <section
          className="parallax-bg"
          style={{
            backgroundImage: `url(${props.isSmallScreen ? images.service.hmps.visualSmall : images.service.hmps.visual})`,
          }}
        >
          <Container>
            <div className="typo">
              <SlideUpText delay="0.2">
                <h1 className="title-bold-03">HMPS</h1>
              </SlideUpText>
              <SlideUpText delay="0.3">
                <h1 className="title-bold-03 fullname">(Hybrid Mice Platform Service)</h1>
              </SlideUpText>
              <SlideUpText delay="0.8">
                <h2 className="title-bold-02" style={{ color: "#d31b00" }}>
                  메타버스 가상공간을 통한 {props.isSmallScreen ? <br /> : " "} 전시회 참여 플랫폼
                </h2>
              </SlideUpText>
              {/* <SlideUpText delay="1.4">
                <div className="py-3 mt-4">
                  <button onClick={() => window.open("http://211.54.150.66:22000/", "_blank")} className="btn_link">
                    바로가기
                  </button>
                </div>
              </SlideUpText> */}
            </div>
          </Container>
        </section>
      </div>

      <div className="panel bottom">
        {/* MICE Platform */}
        <section className="section01">
          <div className="change-panel-wrap" ref={pinWrapRef01}>
            <Container>
              <div className="typo">
                <h2 className="title-bold-02">MICE Platform</h2>
              </div>
            </Container>
            {/* pin */}
            <div className="change-panel-list">
              <article className="change-panel-01 change-01">
                <Container>
                  <div className="img-box">
                    <Image src={images.service.hmps.mice01} alt="메타버스 기술을 활용한 온·오프라인 스마트 융합부스" />
                  </div>
                  <div className="text-box">
                    <h3>
                      메타버스 기술을 활용한
                      <br />
                      온·오프라인 스마트 융합부스
                    </h3>
                    <p>
                      메타버스 가상 공간을 통한 전시 참여가 가능하며 실내 로드맵은 3D, 유저들은 아바타로 표현되어 행사 참여자들의 위치가 표시됩니다. 또한
                      전시공간/ 세미나룸/ 피팅룸 등 어떠한 부스에 참여하고 있는지 실시간으로 확인할 수 있습니다.
                    </p>
                  </div>
                </Container>
              </article>
              <article className="change-panel-01 change-02">
                <Container>
                  <div className="img-box">
                    <Image src={images.service.hmps.mice02} alt="챗봇" />
                  </div>
                  <div className="text-box">
                    <h3>챗봇</h3>
                    <p>
                      기존의 행사 안내, 티켓 구매, 단순 질의 응답을 챗봇 및 플랫폼 미팅테크놀로지에서 해결할 수 있어 최소한의 인력을 배치하여 인력 및 인건비를
                      세이브 할 수 있습니다.
                    </p>
                  </div>
                </Container>
              </article>
              <article className="change-panel-01 change-03">
                <Container>
                  <div className="img-box">
                    <Image src={images.service.hmps.mice03} alt="화상미팅" />
                  </div>
                  <div className="text-box">
                    <h3>화상미팅</h3>
                    <p>
                      별도의 소프트웨어 설치 없이 간편하게 사용할 수 있는 프로세스 서비스를 제공하여 기업 외부 고객사를 위한 OTP(One-Time Password)방식의 비
                      로그인 화상회의 시스템을 제공해줍니다.
                    </p>
                  </div>
                </Container>
              </article>
              <article className="change-panel-01 change-04">
                <Container>
                  <div className="img-box">
                    <Image src={images.service.hmps.mice04} alt="자동 회의록" />
                  </div>
                  <div className="text-box">
                    <h3>자동 회의록</h3>
                    <p>
                      화상 및 음성으로 이루어지는 다자간 회의, 통화에 적용 되어 화상 회의 시 인공지능(AI)을 기반으로 실시간으로 음성을 인식하여 자동으로
                      회의록을 작성합니다.
                    </p>
                  </div>
                </Container>
              </article>
              <article className="change-panel-01 change-05">
                <Container>
                  <div className="img-box">
                    <Image src={images.service.hmps.mice05} alt="행사 라이브스트리밍 소프트웨어" />
                  </div>
                  <div className="text-box">
                    <h3>행사 라이브 스트리밍 소프트웨어</h3>
                    <p>컴퓨터 네트워크 상에 생방송으로 진행되는 각종 MICE 행사를 생방송으로 스트리밍 하는 시스템입니다.</p>
                  </div>
                </Container>
              </article>
              <div className="dot-box">
                {[0, 1, 2, 3, 4].map((index) => {
                  return <i className={activeIndex01 === index ? "active" : null} key={`dot-01-${index}`}></i>;
                })}
              </div>
            </div>
            {/* //pin */}
          </div>
        </section>

        {/* MICE Platform Admin */}
        <section className="section02">
          <div className="change-panel-wrap" ref={pinWrapRef02}>
            <Container>
              <div className="typo">
                <h2 className="title-bold-02">MICE Platform ADMIN</h2>
              </div>
            </Container>
            {/* pin */}
            <div className="change-panel-list">
              <article className="change-panel-02 change-01">
                <Container>
                  <div className="img-box">
                    <Image src={images.service.hmps.admin01} alt="실시간 모니터링 " />
                  </div>
                  <div className="text-box">
                    <h3>실시간 모니터링</h3>
                    <p>각 서비스 페이지, 접속회원 현황, 검색유입율, 컨텐츠 접근 등 관련 행사에 맞는 모니터링 및 통계리포트를 제공합니다.</p>
                  </div>
                </Container>
              </article>
              <article className="change-panel-02 change-02">
                <Container>
                  <div className="img-box">
                    <Image src={images.service.hmps.admin02} alt="간편한 데이터 및 행사 관리" />
                  </div>
                  <div className="text-box">
                    <h3>간편한 데이터 및 행사 관리</h3>
                    <p>
                      기술 지식 없이도 누구나 콘텐츠, 행사관리를 추가 및 편집할 수 있으며 빠르고 쉽게 업데이트 할 수 있습니다. 또한 권한별 접근 권한, 회원 정보
                      변경 등 토탈 관리 기능과 쌓인 데이터를 문서화하여 관리 가능합니다.
                    </p>
                  </div>
                </Container>
              </article>

              <div className="dot-box">
                {[0, 1].map((index) => {
                  return <i className={activeIndex02 === index ? "active" : null} key={`dot-02-${index}`}></i>;
                })}
              </div>
            </div>
            {/* //pin */}
          </div>
        </section>

        {/* 적용사례 */}
        <section className="foot">
          <Reveal threshold={0.2}>
            <FadeInBottom>
              <h5>HMPS 솔루션 소개서</h5>
              <h6>솔루션에 더 자세한 내용은 소개서를 확인해주세요.</h6>
              <a className="btn btn-primary" href="/assets/hmps.pdf" target="_blank">
                HMPS 소개서
              </a>
            </FadeInBottom>
          </Reveal>
        </section>
      </div>
    </main>
  );
});
