/** @jsxImportSource @emotion/react */

import React from "react";
import { css } from "@emotion/react";
import { Image } from "react-bootstrap";

function CertificationItem({ name, id, ...rest }) {
  return (
    <li css={certificationItem} {...rest}>
      <div className="imgBox">
        {id && (
          <>
            <Image
              className="logo"
              src={
                require(`@assets/images/about/certifications/logo_${id}.png`)
                  .default
              }
            />
            <Image
              className="testimonial"
              src={
                require(`@assets/images/about/certifications/${id}.jpg`).default
              }
            />
          </>
        )}
      </div>
      <h3 dangerouslySetInnerHTML={{ __html: name }}></h3>
    </li>
  );
}
const certificationItem = css`
  .imgBox {
    height: 0;
    padding-bottom: 144%;
    border: 4px solid var(--bs-border-blue);
    position: relative;
    img {
      position: absolute;
      &.logo {
        transform: translate(-50%, -50%) scale(0.75);
        top: 50%;
        left: 50%;
      }
      &.testimonial {
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }
  }
  h3 {
    margin-top: 0.75rem;
    color: var(--bs-light-blue);
    opacity: 0.9;
    font-size: 1.3rem;
    line-height: 1.6;
    font-weight: 700;
    text-align: center;
  }
  @media (max-width: 1024px) {
    .imgBox {
      border-width: 2px;
      img {
        &.logo {
          transform: translate(-50%, -50%);
          max-height: 7vw;
        }
      }
    }
    h3 {
      font-size: 1rem;
    }
  }
`;
export default CertificationItem;
