import React from "react";
import { Container, Row, Col, Button, Image } from "react-bootstrap";
import { Reveal } from "react-gsap";
import FadeInBottom from "@components/FadeInBottom";
import images from "@assets/images";

export default React.memo(function Error(props) {
  return (
    <main id="not-found">
      <Container>
        <Reveal>
          <FadeInBottom>
            <Row>
              <Col>
                <h1>
                  죄송합니다.
                  <br />
                  해당 페이지를 찾지 못했습니다.
                </h1>
                <h3>주소가 올바른지 다시 한번 확인해주세요.</h3>
                <Button variant="primary" onClick={() => props.history.push("/")}>
                  홈으로 가기
                </Button>
              </Col>
              <Col lg="auto">
                <Image src={images.errorIllust} />
              </Col>
            </Row>
          </FadeInBottom>
        </Reveal>
      </Container>
    </main>
  );
});
