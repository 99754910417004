import React from "react";
import { ScrollTrigger, Tween } from "react-gsap";
import { useMediaQuery } from "react-responsive";
import PuzzleBase from "@assets/images/home/puzzle_base.svg";
import PuzzleBlue from "@assets/images/home/puzzle_blue.svg";
import PuzzleGray from "@assets/images/home/puzzle_gray.svg";

function Puzzle() {
  const isSmallScreen = useMediaQuery({ maxWidth: 1024 });

  return (
    <div className="puzzle">
      <ScrollTrigger
        start="-400px center"
        end="800px center"
        scrub={0.3}
        // markers
      >
        <Tween from={{ y: -20 }} to={{ y: 0 }}>
          <div>
            <PuzzleBase />
          </div>
        </Tween>
        <Tween from={{ y: 0 }} to={{ y: -40 }}>
          <div>
            <PuzzleGray />
          </div>
        </Tween>
        <Tween from={{ y: isSmallScreen ? -80 : -120 }} to={{ y: 20 }}>
          <div>
            <PuzzleBlue />
          </div>
        </Tween>
      </ScrollTrigger>
    </div>
  );
}

export default React.memo(Puzzle);
