/** @jsxImportSource @emotion/react */

import React, { Fragment } from "react";
import { css } from "@emotion/react";
import { Timeline, Tween } from "react-gsap";
import Infinite from "@assets/images/loading_infinite.svg";

const Loading = () => (
  <Timeline
    target={
      <Fragment>
        <div
          css={spinnerBox}
          style={{ backgroundColor: "var(--bs-primary)" }}
        ></div>
        <div css={infiniteBox}>
          <Infinite />
        </div>
      </Fragment>
    }
  >
    {/* blue down */}
    <Tween
      target={0}
      to={{ autoAlpha: 1 }}
      duration={0.5}
      ease="Power4.easeInOut"
    />
    {/* infinite show */}
    <Tween
      target={1}
      to={{ autoAlpha: 1 }}
      duration={0.3}
      ease="Power2.easeIn"
    />
    {/* infinite hide */}
    <Tween
      target={1}
      to={{ autoAlpha: 0 }}
      delay={1}
      duration={0.3}
      ease="Power2.easeOut"
    />
    {/* blue up */}
    <Tween
      target={0}
      to={{ autoAlpha: 0 }}
      duration={0.5}
      ease="Power4.easeInOut"
    />
  </Timeline>
);

const spinnerBox = css`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  visibility: hidden;
  z-index: 99;
`;
const infiniteBox = css`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  visibility: hidden;
  z-index: 100;
`;
export default Loading;
