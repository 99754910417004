import React from "react";
import { Container } from "react-bootstrap";

// animate
import Ball from "./components/Ball";
import Card from "./components/Card";
import Puzzle from "./components/Puzzle";
import { Reveal } from "react-gsap";
import FadeInBottom from "@components/FadeInBottom";

// image
import Visual from "@assets/images/home/visual.svg";
import CircleBg from "@assets/images/home/bg_circle.svg";
import LineBg from "@assets/images/home/bg_line.svg";
import OurWorks from "@assets/images/home/ourworks.svg";
import Chart from "@assets/images/home/chart.svg";
import ChartBg from "@assets/images/home/bg_chart.svg";
import CircleBgMobile from "@assets/images/home/m_bg_circle.svg";
import LineBgMobile from "@assets/images/home/m_bg_line.svg";

export default React.memo(function Home(props) {
  return (
    <main id="home">
      <div className="home-bg">
        {!props.isSmallScreen ? (
          <>
            <LineBg />
            <CircleBg />
          </>
        ) : (
          <>
            <LineBgMobile />
            <CircleBgMobile />
          </>
        )}
      </div>

      {/* VISUAL */}
      <section id="visual">
        <Container>
          <FadeInBottom delay={0.5}>
            <div className="typo">
              <div>
                <h1>블록체인으로</h1>
              </div>
              <div className="d-flex align-items-center">
                <h1>세상을</h1>
                {/* 연결하는 기술 */}
                <div className="effectBox">
                  <Ball isSmallScreen={props.isSmallScreen} />
                </div>
              </div>
              <h1>
                꿈을
                <span
                  style={{
                    marginRight: props.isSmallScreen ? "2vw" : "1.5rem",
                  }}
                ></span>
                이어주다
              </h1>
            </div>
          </FadeInBottom>
          <Visual className="visualimg" />
        </Container>
      </section>

      {/* 1섹션 */}
      <section>
        <Container>
          <Reveal>
            <FadeInBottom>
              <div className="mainTxt">
                <small className="badge">EXPERIENCE</small>
                <h2>
                  유피체인은 다양한 경험을 하나로
                  <br />
                  연결하는 노하우가 있습니다.
                </h2>
              </div>
            </FadeInBottom>
          </Reveal>

          <Reveal>
            <FadeInBottom>
              <div className="works-box">
                <div className="works" onClick={() => props.history.push("/works/list")}>
                  <OurWorks className="ourWorks" />
                  <Card delay={0} fromy={100} toy={props.isSmallScreen ? "24vh" : "25vh"} />
                  <Card delay={0.1} fromy={80} toy={props.isSmallScreen ? "16vh" : "18vh"} />
                  <Card delay={0.2} fromy={60} toy={props.isSmallScreen ? "10vh" : "12vh"} />
                  <Card delay={0.3} fromy={40} toy={props.isSmallScreen ? "6vh" : "8vh"} />
                  <Card delay={0.4} fromy={20} toy={props.isSmallScreen ? "3vh" : "4vh"} />
                  <Card delay={0.5} fromy={0} toy={0} />
                </div>
              </div>
            </FadeInBottom>
          </Reveal>
        </Container>
      </section>

      {/* 2섹션 */}
      <section>
        <Container>
          <Reveal>
            <FadeInBottom>
              <div className={`mainTxt ${!props.isSmallScreen ? "align-self-end" : ""}`}>
                <small className="badge">COLLABORATION</small>
                <h2>
                  우리의 또 다른 경쟁력은
                  <br />
                  협력과 소통을 통해 시너지를
                  <br />
                  창출하는 것입니다.
                </h2>
              </div>
            </FadeInBottom>
          </Reveal>
          <Puzzle />
        </Container>
      </section>

      {/* 3섹션 */}
      <section className="chart-section">
        <Container>
          <Reveal>
            <FadeInBottom>
              <div className="mainTxt">
                <small className="badge">CHALLENGE</small>
                <h2>
                  우리는 늘 새로운 목표를 향하여,
                  <br />
                  실패를 두려워 하지 않는 도전정신으로
                  <br />더 큰 미래를 그립니다.
                </h2>
              </div>
            </FadeInBottom>
          </Reveal>
          <div className="align-self-end chart">
            <ChartBg className="chartBg" />
            <Chart className="chartSvg" />
          </div>
        </Container>
      </section>
    </main>
  );
});
