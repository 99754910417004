import firebase from "firebase/compat/app";

import "firebase/compat/database";

// Initialize Firebase
const firebaseConfig = {
  apiKey: "AIzaSyC8HlS4UtRQRNtyBlarU37XaPIx7pGiGrc",
  authDomain: "upchain-renewal-a7973.firebaseapp.com",
  projectId: "upchain-renewal-a7973",
  storageBucket: "upchain-renewal-a7973.appspot.com",
  messagingSenderId: "196805009837",
  appId: "1:196805009837:web:901fee314a6d3dd314c124",
  measurementId: "G-E5R204QHJX",
};

if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
}

export const firebase_db = firebase.database();
