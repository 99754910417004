/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useLayoutEffect, useRef, useMemo } from "react";
import { Link } from "react-router-dom";
import { Figure, Spinner, Row } from "react-bootstrap";
import { firebase_db } from "../../firebaseConfig";
import { dateTextFormat } from "@common/util";

// animate
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

// image
import ArrowLeft from "@assets/images/ic_arrow_left.svg";
import ArrowRight from "@assets/images/ic_arrow_right.svg";

export default React.memo(function NewsDetail(props) {
  const { no } = props.match.params;

  const [post, setPost] = useState(null);
  const [allDataLength, setAllDataLength] = useState(0);

  const isInitialMount = useRef(true);

  const titleRef = useRef();
  const contentRef = useRef();
  const typoRef = useRef();

  const prevPost = useMemo(() => Number(no * 1 - 1), [no]);
  const nextPost = useMemo(() => Number(no * 1 + 1), [no]);

  const onClickGoList = () => {
    props.history.push("/news/list");
  };
  const onClickPrevPost = () => {
    props.history.push(`/news/detail/${prevPost}`);
  };
  const onClickNextPost = () => {
    props.history.push(`/news/detail/${nextPost}`);
  };

  // ----------------------------------------------------------------------------------------------
  // ----------------------------------------------------------------------------------------------
  // news data
  useLayoutEffect(() => {
    // reset
    if (ScrollTrigger.getById("fixContentAnimate") !== undefined) {
      ScrollTrigger.getById("fixContentAnimate").kill();
    }
    setPost(null);
    isInitialMount.current = true;

    // dataset
    const dbRef = firebase_db.ref("/news");
    dbRef.once("value").then((snapshot) => {
      let data = snapshot.val()[no];
      setPost(data);
      setAllDataLength(snapshot.val().length);
    });

    return () => {
      dbRef.off();
    };
  }, [no, props.location.pathname]);

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
    } else {
      // animate init
      gsap.set(contentRef.current, { opacity: 0, x: -20 });
      gsap.set(typoRef.current, { opacity: 0, x: -20 });

      // animate play
      gsap.to(typoRef.current, {
        opacity: 1,
        x: 0,
        duration: 1,
        ease: "Power3.easeInOut",
      });
      gsap.to(contentRef.current, {
        opacity: 1,
        x: 0,
        delay: 0.3,
        duration: 1,
        ease: "Power3.easeInOut",
      });

      // animate - pin title
      const fixContentAnimateFnc = setTimeout(() => {
        if (!props.isSmallScreen && post !== null) {
          ScrollTrigger.create({
            id: "fixContentAnimate",
            trigger: contentRef.current,
            start: "top top",
            end: "bottom bottom",
            onLeave: () => titleRef.current.classList.add("leave"),
            onEnterBack: () => titleRef.current.classList.remove("leave"),
            // markers: true,
          });
        }
      }, 500);

      return () => {
        // cleanup

        clearTimeout(fixContentAnimateFnc);
        if (ScrollTrigger.getById("fixContentAnimate") !== undefined) {
          ScrollTrigger.getById("fixContentAnimate").kill();
        }
        console.log("cleanup");
      };
    }
  }, [post]);

  return (
    <main id="news-detail">
      {post !== null ? (
        <div className="news-wrap">
          {/* 좌측 */}
          <article className="news-title" id={`news-title-${no}`} ref={titleRef}>
            <div className="title-inner" ref={typoRef}>
              <h2 className="title-bold-02">
                {post.title}
                <small>
                  {dateTextFormat(post.date)} {post.link ? "보도자료" : "사내소식"}
                </small>
              </h2>
              {post.link && (
                <Link to={{ pathname: post.link }} className="btn btn-outline-dark" target="_blank">
                  원본 보기
                </Link>
              )}
            </div>
          </article>

          {/* 우측 */}
          <article className="news-content" id={`news-content-${no}`} ref={contentRef}>
            <Figure>
              <Figure.Image src={post.visual} />
            </Figure>
            <div className="content-inner">
              {post.subTitle && <h3>{post.subTitle}</h3>}
              <div className="post-content" dangerouslySetInnerHTML={{ __html: post.content }}></div>
              <div className="post-comment" dangerouslySetInnerHTML={{ __html: post.comment }}></div>

              {/* 이전, 다음버튼 */}
              <div className="btn-area">
                <button className="btn-list" onClick={() => onClickGoList()}>
                  목록으로
                </button>
                <div className="d-inline-flex">
                  <button className="btn-prev" onClick={() => onClickPrevPost()} disabled={prevPost < 0}>
                    <ArrowLeft />
                  </button>
                  <button className="btn-next" onClick={() => onClickNextPost()} disabled={nextPost >= allDataLength}>
                    <ArrowRight />
                  </button>
                </div>
              </div>
            </div>
          </article>
        </div>
      ) : (
        <Row className="spinner-inner">
          <Spinner animation="border" variant="primary" />
        </Row>
      )}
    </main>
  );
});
