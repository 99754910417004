/** @jsxImportSource @emotion/react */

import React from "react";
import { css } from "@emotion/react";
import { SplitChars, Timeline, Tween } from "react-gsap";
import { useMediaQuery } from "react-responsive";
import { isMobile } from "react-device-detect";

function Ball() {
  const isSmallScreen = useMediaQuery({ maxWidth: 1024 });
  const isMobileScreen = useMediaQuery({ maxWidth: 640 });
  const isMobileDevice = isMobile;
  return (
    <>
      {/* 볼 타임라인 */}
      <div css={ballBox} style={{ top: isMobileDevice ? "-0.4vw" : 0 }}>
        <Timeline target={<div css={ball} />}>
          {/* 1. 노출 */}
          <Tween
            from={{
              opacity: 0,
              transform: "scale(0)",
            }}
            to={{
              opacity: 1,
              transform: "scale(1)",
            }}
            duration={0.6}
            delay={1}
            ease="elastic.out(0.2, 0.1)"
          />

          {/* 2. 늘어남 */}
          <Tween
            to={{
              width: isSmallScreen
                ? isMobileScreen
                  ? "55vw"
                  : "50vw"
                : "405px",
            }}
            duration={1}
          />

          {/* 3. 볼 변환, 이동 */}
          <Tween
            to={{
              width: isSmallScreen ? "12vw" : "90px",
              x: isSmallScreen ? (isMobileScreen ? "43vw" : "38vw") : "315px",
            }}
            delay={0.5}
            duration={1}
          />

          {/* 4. 오른쪽으로 이동 */}
          <Tween
            to={{
              x: isSmallScreen ? "54vw" : "500px",
              backgroundColor: "#EEF5FF",
            }}
            duration={1.5}
            ease="elastic.out(0.2, 0.1)"
          />

          {/* 5. 빠져서 사라짐 */}
          <Tween
            to={{
              x: isSmallScreen ? "53vw" : "500px",
              opacity: 0,
              transform: "scale(0.5)",
            }}
            duration={2}
            ease="elastic.out(0.2, 0.1)"
          />
        </Timeline>
      </div>

      {/* 연결하는 기술 텍스트 */}
      <div css={charBox}>
        <Timeline
          target={
            <SplitChars wrapper={<span css={char} />}>연결하는 기술</SplitChars>
          }
        >
          {/* 1. 나타남 */}
          <Tween
            from={{
              opacity: 0,
              x: 10,
            }}
            to={{
              opacity: 1,
              x: 0,
            }}
            stagger={0.15}
            delay={1.6}
            ease="back.out(1.7)"
          ></Tween>

          {/* 2. 색변환 */}
          <Tween
            to={{
              color: "#fff",
            }}
            delay={-0.5}
            stagger={0.15}
            ease="back.out(1.7)"
          />
        </Timeline>
      </div>
    </>
  );
}

const ballBox = css`
  position: absolute;
  top: 0;
  left: -15px;
  z-index: 0;
  display: flex;
  height: 100%;
  align-items: center;
  @media (max-width: 1024px) {
    left: -2vw;
  }
`;
const ball = css`
  width: 90px;
  height: 90px;
  border-radius: 100px;
  background: var(--bs-yellow);
  @media (max-width: 1024px) {
    width: 12vw;
    height: 12vw;
  }
`;
const charBox = css`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  display: flex;
  height: 100%;
  align-items: center;
`;
const char = css`
  display: inline-flex;
  color: var(--bs-dark);
  font-weight: 700;
  font-size: 4rem;
  line-height: 1.4;
  &:nth-of-type(4) {
    margin-right: 1.5rem;
  }
  @media (max-width: 1024px) {
    font-size: 8vw;
    line-height: 12vw;
    &:nth-of-type(4) {
      margin-right: 2vw;
    }
  }
  @media (max-width: 640px) {
    font-size: 9vw;
  }
`;
export default Ball;
