import React, { useState, useMemo } from "react";
import { Container, Nav, Image, Button } from "react-bootstrap";
import { images } from "@assets";

// animate
import { Reveal } from "react-gsap";
import FadeInBottom from "@components/FadeInBottom";
import SlideUpText from "@components/SlideUpText";

export default React.memo(function Uptalk(props) {
  // console.log(props)
  let [workTab, setWorkTab] = useState(0);
  let [documentTab, setDocumentTab] = useState(0);

  const goContactPage = () => {
    props.history.push("/contact");

    // go scroll top
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "instant",
    });
  };

  const tabWork = useMemo(
    () => [
      {
        title: "누구나 쉽게 \n가입 가능",
        text: "URL만 있으면 누구나 쉽게 특별한 교육 없이 가입하고 사용할 수 있으며 외부 고객사와 업무관리까지 가능합니다.",
      },
      {
        title: "프로젝트별 \n관리",
        text: "업무 시작부터 종료까지의 상태를 구분해 직관적으로 업무 상태를 파악하고 빠르게 원하는 요청사항을 찾을 수 있습니다.",
      },
      {
        title: "효율적인 \n커뮤니케이션",
        text: "익숙한 메신저 형태로 빠른 업무 소통이 가능하며 내장 화상회의 시스템을 통해 실시간 소통, 녹화 기능을 제공해 계약 증명이 가능합니다.",
      },
    ],
    []
  );
  const tabDocument = useMemo(
    () => [
      {
        title: "블록체인 기반 \n스마트 계약",
        text: "스마트 계약, 블록체인으로 계약증명을 관리하고 스타트업, 중소상공인에 최적화된 표준화된 계약서를 제공해줍니다.",
      },
      {
        title: "무기한 \n검색기능",
        text: "주고받은 메시지와 공지, 파일, 일정, 요청사항, 화상미팅 내역을 무기한으로 검색할 수 있습니다.",
      },
      {
        title: "AI 분석 및 \n자동레이블링",
        text: "모바일 PC 기반의 메신저 화상회의 시스템을 통해지나간 화상회의 내용, 영상물을 빠르게 검색합니다.",
      },
    ],
    []
  );

  return (
    <main id="svc-uptalk" className="service">
      {/* 비쥬얼 */}
      <section className="panel visual">
        <Container>
          <div className="typo">
            <SlideUpText delay="0.2">
              <h1 className="title-bold-03">UP TALK</h1>
            </SlideUpText>
            <SlideUpText delay="0.8">
              <p className="sub-title-white">ALL IN ONE 비즈니스 협업 관리 서비스</p>
            </SlideUpText>
          </div>
          <Reveal threshold={0.2}>
            <FadeInBottom>
              {props.isSmallScreen ? (
                <Image className="main-img" src={images.service.uptalk.visualMobile} />
              ) : (
                <Image className="main-img" src={images.service.uptalk.visual} />
              )}
            </FadeInBottom>
          </Reveal>
        </Container>
      </section>

      {/* 텍스트 영역 */}
      <section className="text-area">
        <Container>
          <Reveal>
            <FadeInBottom>
              <div>
                <h5>
                  균형 있는 워라벨을 찾고 싶다면?
                  <br />
                  업무는 업무답게 관리하고,
                  {props.isSmallScreen ? <br /> : " "}개인 메신저와 확실히 분리하자!
                </h5>
              </div>
            </FadeInBottom>
          </Reveal>
        </Container>
      </section>

      {/* 업무소통 탭 */}
      <section className="work-tab pt-0">
        <Container>
          <Reveal threshold={0.2}>
            <FadeInBottom>
              {props.isSmallScreen ? (
                <div className="tab-area">
                  <div className="top-area">
                    <p className="sub-title">업무소통</p>
                    <p className="title">비대면 업무에{!props.isSmallScreen ? <br /> : " "} 최적화된 시스템</p>
                    {tabWork.map((tab, index) => {
                      return (
                        <Image
                          key={`work-img-${index}`}
                          src={require(`@assets/images/service/uptalk/tab1_${index + 1}.png`).default}
                          style={{
                            display: workTab === index ? "block" : "none",
                          }}
                        />
                      );
                    })}
                  </div>
                  <div className="bottom-area">
                    <Nav variant="tabs" defaultActiveKey="link-0">
                      {tabWork.map((tab, index) => {
                        return (
                          <Nav.Item key={`work-text-${index}`}>
                            <Nav.Link eventKey={`link-${index}`} onClick={() => setWorkTab(index)}>
                              <h5 className="title">{tab.title}</h5>
                              <p
                                className="text"
                                style={{
                                  display: workTab === index ? "block" : "none",
                                }}
                              >
                                {tab.text}
                              </p>
                            </Nav.Link>
                          </Nav.Item>
                        );
                      })}
                    </Nav>
                  </div>
                </div>
              ) : (
                <div className="tab-area">
                  <div className="left-area">
                    <p className="sub-title">업무소통</p>
                    <p className="title">비대면 업무에{!props.isSmallScreen ? <br /> : " "} 최적화된 시스템</p>
                    <Nav variant="tabs" defaultActiveKey="link-0">
                      {tabWork.map((tab, index) => {
                        return (
                          <Nav.Item key={`work-text-${index}`}>
                            <Nav.Link eventKey={`link-${index}`} onClick={() => setWorkTab(index)}>
                              <h5 className="title">{tab.title}</h5>
                              <p
                                className="text"
                                style={{
                                  display: workTab === index ? "block" : "none",
                                }}
                              >
                                {tab.text}
                              </p>
                            </Nav.Link>
                          </Nav.Item>
                        );
                      })}
                    </Nav>
                  </div>
                  <div className="right-area">
                    {tabWork.map((tab, index) => {
                      return (
                        <Image
                          key={`work-img-${index}`}
                          src={require(`@assets/images/service/uptalk/tab1_${index + 1}.png`).default}
                          style={{
                            display: workTab === index ? "block" : "none",
                          }}
                        />
                      );
                    })}
                  </div>
                </div>
              )}
            </FadeInBottom>
          </Reveal>
        </Container>
      </section>

      {/* 문서관리 탭 */}
      <section className="document-tab">
        <Container>
          <Reveal threshold={0.2}>
            <FadeInBottom>
              {props.isSmallScreen ? (
                <div className="tab-area">
                  <div className="top-area">
                    <p className="sub-title">문서관리</p>
                    <p className="title">간편한{!props.isSmallScreen ? <br /> : " "} 계약증명 관리</p>
                    {tabDocument.map((tab, index) => {
                      return (
                        <Image
                          key={`document-img-${index}`}
                          src={require(`@assets/images/service/uptalk/tab2_${index + 1}.png`).default}
                          style={{
                            display: documentTab === index ? "block" : "none",
                          }}
                        />
                      );
                    })}
                  </div>
                  <div className="bottom-area">
                    <Nav variant="tabs" defaultActiveKey="link-0">
                      {tabDocument.map((tab, index) => {
                        return (
                          <Nav.Item key={`document-text-${index}`}>
                            <Nav.Link eventKey={`link-${index}`} onClick={() => setDocumentTab(index)}>
                              <h5 className="title">{tab.title}</h5>
                              <p
                                className="text"
                                style={{
                                  display: documentTab === index ? "block" : "none",
                                }}
                              >
                                {tab.text}
                              </p>
                            </Nav.Link>
                          </Nav.Item>
                        );
                      })}
                    </Nav>
                  </div>
                </div>
              ) : (
                <div className="tab-area">
                  <div className="left-area">
                    <p className="sub-title">문서관리</p>
                    <p className="title">
                      간편한
                      <br />
                      계약증명 관리
                    </p>
                    <Nav variant="tabs" defaultActiveKey="link-0">
                      {tabDocument.map((tab, index) => {
                        return (
                          <Nav.Item key={`document-text-${index}`}>
                            <Nav.Link eventKey={`link-${index}`} onClick={() => setDocumentTab(index)}>
                              <h5 className="title">{tab.title}</h5>
                              <p
                                className="text"
                                style={{
                                  display: documentTab === index ? "block" : "none",
                                }}
                              >
                                {tab.text}
                              </p>
                            </Nav.Link>
                          </Nav.Item>
                        );
                      })}
                    </Nav>
                  </div>
                  <div className="right-area">
                    {tabDocument.map((tab, index) => {
                      return (
                        <Image
                          key={`document-img-${index}`}
                          src={require(`@assets/images/service/uptalk/tab2_${index + 1}.png`).default}
                          style={{
                            display: documentTab === index ? "block" : "none",
                          }}
                        />
                      );
                    })}
                  </div>
                </div>
              )}
            </FadeInBottom>
          </Reveal>
        </Container>
      </section>

      {/* 홍보영상 */}
      <section className="video-area">
        <Container>
          <div className="video-con">
            <video src="/assets/videos/uptalk.mp4" loop autoPlay muted controls playsInline />
          </div>
        </Container>
      </section>

      {/* 연간 이용료 */}
      <section className="yearly-fee">
        <Container>
          <Reveal threshold={0.2}>
            <FadeInBottom>
              <h5>연간 이용료 안내</h5>
              <div className="fee-area">
                <h6>업체당</h6>
                <div>
                  <p>
                    연&nbsp;<span>2,000,000</span>원
                  </p>
                </div>
              </div>
            </FadeInBottom>
          </Reveal>
        </Container>
      </section>

      {/* 문의사항 */}
      <section className="foot">
        <Reveal threshold={0.2}>
          <FadeInBottom>
            <h5>문의사항이 있으신가요?</h5>
            <h6>서비스에 대해 무엇이든 물어보세요.</h6>
            <Button variant="primary" onClick={() => goContactPage()}>
              문의하기
            </Button>
          </FadeInBottom>
        </Reveal>
      </section>
    </main>
  );
});
