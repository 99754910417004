/** @jsxImportSource @emotion/react */

import React from "react";
import { css } from "@emotion/react";
import { Image } from "react-bootstrap";
import { useMediaQuery } from "react-responsive";
import { images } from "@assets";

function PartnerList() {
  const isSmallScreen = useMediaQuery({ maxWidth: 1024 });

  return (
    <div css={partnerList}>
      <ul>
        <li>
          <Image src={images.about.partner01} />
        </li>
        <li>
          <Image src={images.about.partner02} />
        </li>
        <li>
          <Image src={images.about.partner03} />
        </li>
        <li>
          <Image src={images.about.partner04} />
        </li>
        <li>
          <Image src={images.about.partner05} />
        </li>
        <li>
          <Image src={images.about.partner06} />
        </li>
        <li>
          <Image src={images.about.partner07} />
        </li>
        <li>
          <Image src={images.about.partner08} />
        </li>
        {!isSmallScreen && (
          <>
            <li>
              <Image src={images.about.partner09} />
            </li>
            <li>
              <Image src={images.about.partner10} />
            </li>
            <li>
              <Image src={images.about.partner11} />
            </li>
            <li>
              <Image src={images.about.partner12} />
            </li>
            <li>
              <Image src={images.about.partner13} />
            </li>
            <li>
              <Image src={images.about.partner14} />
            </li>
            <li>
              <Image src={images.about.partner15} />
            </li>
            <li>
              <Image src={images.about.partner16} />
            </li>
          </>
        )}
      </ul>
      <ul>
        {!isSmallScreen && (
          <>
            <li>
              <Image src={images.about.partner01} />
            </li>
            <li>
              <Image src={images.about.partner02} />
            </li>
            <li>
              <Image src={images.about.partner03} />
            </li>
            <li>
              <Image src={images.about.partner04} />
            </li>
            <li>
              <Image src={images.about.partner05} />
            </li>
            <li>
              <Image src={images.about.partner06} />
            </li>
            <li>
              <Image src={images.about.partner07} />
            </li>
            <li>
              <Image src={images.about.partner08} />
            </li>
          </>
        )}
        <li>
          <Image src={images.about.partner09} />
        </li>
        <li>
          <Image src={images.about.partner10} />
        </li>
        <li>
          <Image src={images.about.partner11} />
        </li>
        <li>
          <Image src={images.about.partner12} />
        </li>
        <li>
          <Image src={images.about.partner13} />
        </li>
        <li>
          <Image src={images.about.partner14} />
        </li>
        <li>
          <Image src={images.about.partner15} />
        </li>
        <li>
          <Image src={images.about.partner16} />
        </li>
      </ul>
      {isSmallScreen && (
        <ul>
          <li>
            <Image src={images.about.partner05} />
          </li>
          <li>
            <Image src={images.about.partner06} />
          </li>
          <li>
            <Image src={images.about.partner07} />
          </li>
          <li>
            <Image src={images.about.partner08} />
          </li>
          <li>
            <Image src={images.about.partner09} />
          </li>
          <li>
            <Image src={images.about.partner10} />
          </li>
          <li>
            <Image src={images.about.partner11} />
          </li>
          <li>
            <Image src={images.about.partner12} />
          </li>
        </ul>
      )}
    </div>
  );
}
const partnerList = css`
  @keyframes list-ani-01 {
    0% {
      transform: translateX(-50%);
    }
    100% {
      transform: translateX(50%);
    }
  }
  @keyframes list-ani-02 {
    0% {
      transform: translateX(50%);
    }
    100% {
      transform: translateX(-50%);
    }
  }
  overflow: hidden;
  ul {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 4rem;
    animation-duration: 45s;
    animation-iteration-count: infinite;
    animation-direction: alternate;
    animation-timing-function: linear;
    &:nth-of-type(1),
    &:nth-of-type(3) {
      animation-name: list-ani-01;
    }
    &:nth-of-type(2) {
      animation-name: list-ani-02;
    }
    li {
      margin: 0 2rem;
      img {
        max-height: 36px;
      }
    }
  }
  @media (max-width: 1024px) {
    ul {
      animation-duration: 30s;
      margin-bottom: 2rem;
      li {
        margin: 0 1rem;
        img {
          min-height: 20px;
          max-height: 4vw;
        }
      }
    }
  }
`;
export default PartnerList;
