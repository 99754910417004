/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef, useLayoutEffect } from "react";
import { Container, Image } from "react-bootstrap";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import { images } from "@assets";

// animate
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { Reveal } from "react-gsap";
import FadeInBottom from "@components/FadeInBottom";
import SlideUpText from "@components/SlideUpText";

const circleText01 = ["트래블룰", "FDS", "AML"];
const circleText02 = [
  {
    title: "프로세스 모듈별<br />최적화 아키텍쳐 구성",
    small: "트래픽 Scale out",
  },
  {
    title: "UP Wallet",
    small: "Private-Key 분리",
  },
  {
    title: "ViaBTC<br />자체 매칭 엔진 개발",
    small: "처리속도 1,500 TPS<br />DB Partitioning",
  },
  {
    title: "코인 전송<br />수수료 최적화",
    small: "수수료 절감",
  },
];
export default React.memo(function Upts(props) {
  const visualRef = useRef();

  const pinWrapRef01 = useRef();
  const keywordRef = useRef();
  const [activeIndex01, setActiveIndex01] = useState(0);

  const pinWrapRef02 = useRef();
  const rangeActiveRef = useRef();
  const rangeTextRef = useRef();
  const rangeTextLast = useRef();
  const [rangeText, setRangeText] = useState([]);
  const [activeIndex02, setActiveIndex02] = useState(0);

  const [zommImageShow, setZommImageShow] = useState(false);

  // ----------------------------------------------------------------------------------------------
  // ----------------------------------------------------------------------------------------------
  // kill
  useLayoutEffect(() => {
    return () => {
      // animate cover panel
      if (ScrollTrigger.getById("animateCoverPanel") !== undefined) {
        ScrollTrigger.getById("animateCoverPanel").kill();
      }

      // animate contents change - 가상 자산 거래소 금융권 제도화 빠른 대응
      if (ScrollTrigger.getById("pinChangeContents01") !== undefined) {
        ScrollTrigger.getById("pinChangeContents01").kill();
        // console.log("kill01");
      }

      // animate contents change - UPTS는 어떻게 해결하고 있나요?
      if (ScrollTrigger.getById("pinChangeContents02") !== undefined) {
        ScrollTrigger.getById("pinChangeContents02").kill();
        // console.log("kill02");
      }
    };
  }, []);

  useEffect(() => {
    // animate cover panel
    ScrollTrigger.create({
      id: "animateCoverPanel",
      trigger: visualRef.current,
      start: "top top",
      end: "bottom top",
      pin: true,
      pinSpacing: false,
    });

    // animate highlight words
    const highlightWords = gsap.utils.toArray(".highlight");
    highlightWords.forEach((word, i) => {
      ScrollTrigger.create({
        id: `animateHighlightWords${i}`,
        trigger: word,
        start: "200px bottom",
        endtrigger: ".section01",
        toggleClass: "active",
      });
    });

    // animate contents change - 가상 자산 거래소 금융권 제도화 빠른 대응
    const changePanels01 = gsap.utils.toArray(".change-panel-01");
    let currentPanel = changePanels01[0];
    const setPanel01 = (newPanel, i) => {
      gsap.to(currentPanel, {
        y: -60,
        opacity: 0,
      });
      gsap.to(newPanel, {
        y: 0,
        opacity: 1,
        duration: 0.3,
      });
      currentPanel = newPanel;
      setActiveIndex01(i);
    };

    let pinWrapTop01 = window.pageYOffset + pinWrapRef01.current.getBoundingClientRect().top;
    gsap.defaults({ overwrite: "auto", duration: 0.3 });

    ScrollTrigger.create({
      id: "pinChangeContents01",
      trigger: pinWrapRef01.current,
      start: "top top",
      end: "bottom bottom",
      pin: true,
      pinSpacing: 0,
      onLeave: () => pinWrapRef01.current.classList.add("leave"),
      onEnterBack: () => pinWrapRef01.current.classList.remove("leave"),
      // markers: true,
    });

    changePanels01.forEach((panel, i) => {
      ScrollTrigger.create({
        id: `animateChangeContents01-${i}`,
        trigger: panel,
        start: () => (props.isSmallScreen ? pinWrapTop01 + (i - 0.5) * (window.innerHeight * 0.8) : pinWrapTop01 + (i - 0.5) * (window.innerHeight * 0.6)),
        end: () => (props.isSmallScreen ? pinWrapTop01 + (i + 0.5) * (window.innerHeight * 0.8) : pinWrapTop01 + (i + 0.5) * (window.innerHeight * 0.6)),
        onToggle: (self) => self.isActive && setPanel01(panel, i),
        toggleActions: "play pause resume reset",
        // markers: true,
      });
    });

    // animate contents change - UPTS는 어떻게 해결하고 있나요?
    const changePanels02 = gsap.utils.toArray(".change-panel-02");
    const rangeTimeline = gsap.timeline();
    gsap.set(rangeActiveRef.current, { width: 0 });
    gsap.set(rangeTextLast.current, {
      color: "var(--bs-gray)",
      fontWeight: 500,
    });
    rangeTimeline
      .to(rangeActiveRef.current, {
        width: "35%",
        ease: "ease",
        duration: 0.75,
        delay: 0.3,
      })
      .to(rangeActiveRef.current, {
        immediateRender: false,
        width: "64%",
        ease: "ease",
        duration: 0.75,
      })
      .to(rangeActiveRef.current, {
        immediateRender: false,
        width: "100%",
        ease: "ease",
        duration: 0.75,
      });
    // .to(rangeTextLast.current, {
    //   immediateRender: false,
    //   color: "var(--bs-primary)",
    //   fontWeight: "bold",
    //   duration: 0.5,
    // });

    const setPanel02 = (i) => {
      let textArray = [];
      if (i === 0) {
        textArray = [
          `서버 별 역할${props.isSmallScreen ? "<br />" : " "} 지정,분담`,
          `효과적인${props.isSmallScreen ? "<br />" : " "}데이터 보관`,
          `트래픽에 따른${props.isSmallScreen ? "<br />" : " "}서버 Scale Out`,
          `효과적인 분산${props.isSmallScreen ? "<br />" : " "}시스템 최적화`,
        ];
      } else if (i === 1) {
        textArray = [
          `모든 Coin의${props.isSmallScreen ? "<br />" : " "}독자적 Node`,
          `Private-Key 분리로${props.isSmallScreen ? "<br />" : " "}강화된 보안성`,
          `다양한 Coin${props.isSmallScreen ? "<br />" : " "}Node 경험`,
          `안전적인 개인 자산${props.isSmallScreen ? "<br />" : " "}& 정보 보호`,
        ];
      } else if (i === 2) {
        textArray = [
          `거래 처리 속도${props.isSmallScreen ? "<br />" : " "}1,500 TPS`,
          "Dockerize로 인한<br />코인 상장,서버 증설에 확장성",
          `DB${props.isSmallScreen ? "<br />" : " "}Partitioning`,
          `대규모 테이블의${props.isSmallScreen ? "<br />" : " "}파티션 분할화`,
        ];
      } else if (i === 3) {
        textArray = [
          "독자적 기술을 통한<br />Coin 수수료 최적화",
          `효율적인${props.isSmallScreen ? "<br />" : " "}수익 안정화`,
          `유동적인 Coin${props.isSmallScreen ? "<br />" : " "}수수료 계산`,
          "최소 지출 설계",
        ];
      }
      setActiveIndex02(i);
      setRangeText(textArray);
      rangeTimeline.restart();
    };

    let pinWrapTop02 = window.pageYOffset + pinWrapRef02.current.getBoundingClientRect().top;
    gsap.defaults({ overwrite: "true", duration: 0.3 });

    ScrollTrigger.create({
      id: "pinChangeContents02",
      trigger: pinWrapRef02.current,
      start: "top top",
      end: "bottom bottom",
      pin: true,
      pinSpacing: 0,
      onLeave: () => pinWrapRef02.current.classList.add("leave"),
      onEnterBack: () => pinWrapRef02.current.classList.remove("leave"),
      // markers: true,
    });

    changePanels02.forEach((panel, i) => {
      ScrollTrigger.create({
        id: `animateChangeContents02-${i}`,
        trigger: panel,
        start: () => (props.isSmallScreen ? pinWrapTop02 + (i - 0.5) * (window.innerHeight * 0.8) : pinWrapTop02 + (i - 0.5) * (window.innerHeight * 0.6)),
        end: () => (props.isSmallScreen ? pinWrapTop02 + (i + 0.5) * (window.innerHeight * 0.8) : pinWrapTop02 + (i + 0.5) * (window.innerHeight * 0.6)),
        onToggle: (self) => self.isActive && setPanel02(i),
        toggleActions: "play pause resume reset",
        // markers: true,
      });
    });

    // kill
    return () => {
      // animate cover panel
      if (ScrollTrigger.getById("animateCoverPanel") !== undefined) {
        ScrollTrigger.getById("animateCoverPanel").kill();
      }

      // animate highlight words
      if (highlightWords.length > 0 && ScrollTrigger.getById("animateHighlightWords0") !== undefined) {
        for (let i in highlightWords) {
          ScrollTrigger.getById(`animateHighlightWords${i}`).kill();
        }
      }

      // animate contents change - 가상 자산 거래소 금융권 제도화 빠른 대응
      if (changePanels01.length > 0 && ScrollTrigger.getById("animateChangeContents01-0") !== undefined) {
        for (let i in changePanels01) {
          ScrollTrigger.getById(`animateChangeContents01-${i}`).kill();
          // console.log(`kill01-${i}`);
        }
      }

      // animate contents change - UPTS는 어떻게 해결하고 있나요?
      if (rangeTimeline !== undefined) {
        rangeTimeline.kill();
      }
      if (changePanels02.length > 0 && ScrollTrigger.getById("animateChangeContents02-0") !== undefined) {
        for (let i in changePanels02) {
          ScrollTrigger.getById(`animateChangeContents02-${i}`).kill();
          // console.log(`kill02-${i}`);
        }
      }
    };
  }, []);

  return (
    <main id="svc-ts" className="service">
      <div className="panel visual" ref={visualRef}>
        <section className="parallax-bg" style={{ backgroundImage: `url(${images.service.upts.visual})` }}>
          <Container>
            <div className="typo">
              <SlideUpText delay="0.2">
                <h1 className="title-bold-03">UPTS</h1>
              </SlideUpText>
              <SlideUpText delay="0.3">
                <h1 className="title-bold-03 fullname">(UP Trading System)</h1>
              </SlideUpText>
              <SlideUpText delay="0.8">
                <p className="sub-title-white">
                  시스템의 안정성과 보안에 중점을 둔{props.isSmallScreen ? <br /> : " "}
                  증권형 기반 가상 자산 거래소 시스템
                </p>
              </SlideUpText>
            </div>
          </Container>
        </section>
      </div>

      <div className="panel bottom">
        {/* SECTION-01 */}
        <section className="section01">
          <Container>
            <Reveal>
              <FadeInBottom>
                <div className="typo">
                  <h2 className="title-bold-02">거래소 보안이슈 걱정되지는 않으신가요?</h2>
                  <p>많은 암호화폐 거래유저들이 자신의 계좌 해킹 및 정보 유출 등의 사유로 거래소 이용을 기피하고 있습니다.</p>
                </div>
              </FadeInBottom>
            </Reveal>
            <Reveal threshold={0.2}>
              <FadeInBottom>
                <ul className="words">
                  <li>
                    <span>금융범죄</span>
                    <span className="highlight">계좌 해킹</span>
                    <span>자산안전</span>
                    <span>자금세탁</span>
                    <span>정보</span>
                  </li>
                  <li>
                    <span>보이스피싱</span>
                    <span>보안시스템</span>
                    <span>코인보관</span>
                    <span>해킹사고</span>
                  </li>
                  <li>
                    <span>접근권한</span>
                    <span>서버다운</span>
                    <span>거래소</span>
                    <span>보안규제</span>
                    <span>정보</span>
                  </li>
                  <li>
                    <span>사기</span>
                    <span>위변조</span>
                    <span>보호</span>
                    <span className="highlight">정보 유출</span>
                    <span>인출</span>
                    <span>안정성</span>
                  </li>
                </ul>
              </FadeInBottom>
            </Reveal>
          </Container>
        </section>

        {/* SECTION-02 */}
        <section className="section02">
          <Container>
            <Reveal>
              <FadeInBottom>
                <div className="typo">
                  <h2 className="title-bold-02">
                    정보보호관리체계(ISMS) 인증 취득
                    {props.isSmallScreen ? <br /> : " "}
                    가상 자산 거래소 솔루션
                  </h2>
                  <p>
                    정보보호 관리체계 수립 및 운영, 정보보호대책 요구사항, 개인정보 처리단계별 요구사항 등{!props.isSmallScreen ? <br /> : " "}
                    3개 분야 102개 항목에 검증을 바탕으로, 정보보호에 안정성이 검증된 솔루션
                  </p>
                </div>
              </FadeInBottom>
            </Reveal>
            <Reveal>
              <FadeInBottom>
                <div className="text-center"></div>
              </FadeInBottom>
            </Reveal>
          </Container>
        </section>

        {/* SECTION-03 */}
        <section className="section03">
          <Container>
            <div className="change-panel-wrap-01" ref={pinWrapRef01}>
              <div className="typo">
                <h2 className="title-bold-02">가상 자산 거래소 금융권 제도화 빠른 대응</h2>
              </div>

              {/* pin */}
              <div className="change-panel-list">
                <article className="change-panel-01 change-01">
                  <Image src={images.service.upts.tr} />
                  <p>
                    트래블룰(Travel Rule)이란, 가상자산을 이동할때, 송신을 담당하는 가상자산사업자 즉, 코인거래소가 자산 이동관련 정보를
                    {!props.isSmallScreen ? <br /> : " "}
                    수취인에게 제공해야할 자금세탁방지를 위한 제도이며, UPTS에서 해당 제도에 대한 대응이 가능한 가상화폐 거래 시스템입니다.
                  </p>
                </article>
                <article className="change-panel-01 change-02">
                  <Image src={images.service.upts.fds} />
                  <p>
                    이상거래탐지시스템 (FDS, Fraud Detection System)은 전자금융거래 시 단말기 정보와 접속 정보, 거래정보 등을 수집 및
                    {!props.isSmallScreen ? <br /> : " "}분석하여 이상 금융 거래를 차단하는 기술이며, UPTS에서 해당 제도에 대한 대응이 가능한 가상화폐 거래
                    시스템입니다.
                  </p>
                </article>
                <article className="change-panel-01 change-03">
                  <Image src={images.service.upts.aml} />
                  <p>
                    AML (Anti Money Laundering)은 불법자금 세탁을 적발하고 예방하기위한 관리체계로, 금융시장의 투명성과 공정성을
                    {!props.isSmallScreen ? <br /> : " "}
                    확보하기 위한 시스템이며, UPTS에서 해당 제도에 대한 대응이 가능한 가상화폐 거래 시스템입니다.
                  </p>
                </article>
              </div>
              {/* //pin */}

              <div className="keywords" ref={keywordRef}>
                {circleText01.length > 0 &&
                  circleText01.map((text, index) => {
                    return <div key={`keyword-${index}`} className={activeIndex01 === index ? "active" : ""} dangerouslySetInnerHTML={{ __html: text }}></div>;
                  })}
              </div>
            </div>
          </Container>
        </section>

        {/* SECTION-04 */}
        <section className="section04">
          <Container>
            <div className="change-panel-wrap-02" ref={pinWrapRef02}>
              <div className="typo">
                <h2 className="title-bold-02">
                  UPTS는 어떻게
                  {props.isSmallScreen ? <br /> : " "}
                  해결하고 있나요?
                </h2>
              </div>

              <div className={`circle-wrap state${activeIndex02}`}>
                {circleText02.length > 0 &&
                  circleText02.map((text, index) => {
                    return (
                      <div key={`circle-${index}`} className={`circle ${activeIndex02 === index ? "active" : ""}`}>
                        <strong dangerouslySetInnerHTML={{ __html: text.title }}></strong>
                        <small dangerouslySetInnerHTML={{ __html: text.small }}></small>
                      </div>
                    );
                  })}
                <div className="pagination-wrap">
                  {circleText02.length > 0 &&
                    circleText02.map((text, index) => {
                      return <small key={`pagination-${index}`} className={`pagination ${activeIndex02 === index ? "active" : ""}`}></small>;
                    })}
                </div>
              </div>

              <div className="range-area">
                <div className="range-bar">
                  <div className="range-active" ref={rangeActiveRef}></div>
                </div>
                <ul className="range-text" ref={rangeTextRef}>
                  <li dangerouslySetInnerHTML={{ __html: rangeText[0] }}></li>
                  <li dangerouslySetInnerHTML={{ __html: rangeText[1] }}></li>
                  <li ref={rangeText[3] ? null : rangeTextLast} dangerouslySetInnerHTML={{ __html: rangeText[2] }}></li>
                  {rangeText[3] && <li ref={rangeTextLast} dangerouslySetInnerHTML={{ __html: rangeText[3] }}></li>}
                </ul>
              </div>

              {/* pin */}
              <article className="change-panel-02 change-01"></article>
              <article className="change-panel-02 change-02"></article>
              <article className="change-panel-02 change-03"></article>
              <article className="change-panel-02 change-04"></article>
              {/* //pin */}
            </div>
          </Container>
        </section>

        {/* SECTION-05 */}
        <section className="section05">
          <Container fluid>
            <Reveal>
              <FadeInBottom>
                <div className="imgBox">
                  <Image src={props.isSmallScreen ? images.service.upts.diagramMobile : images.service.upts.diagram} />
                  <button className="btn_zoom" onClick={() => setZommImageShow(true)}></button>
                </div>
              </FadeInBottom>
            </Reveal>
          </Container>
        </section>

        {/* SECTION-06 */}
        <section className="section06">
          <Container>
            <div className="typo">
              <h2 className="title-bold-02 text-white">
                실시간 동시 거래 100,000건 처리
                {props.isSmallScreen ? <br /> : " "}및 트래픽 Scale Out
              </h2>
            </div>
            <div className="mt-4">
              <video src="/assets/videos/upts.mp4" loop autoPlay muted playsInline />
            </div>
          </Container>
        </section>

        {/* SECTION-07 */}
        <section className="section07">
          <Container>
            <Reveal threshold={0.2}>
              <FadeInBottom>
                <div className="typo">
                  <h2 className="title-bold-02">
                    Device에 맞춘 최적화{props.isSmallScreen ? <br /> : " "}
                    Interface 구성
                  </h2>
                  <p>
                    원하시는 서비스에 맞도록 가상 자산 거래 솔루션을
                    {props.isSmallScreen ? <br /> : " "}웹모바일 / Native 앱으로 구축해드립니다.
                  </p>
                </div>
              </FadeInBottom>
            </Reveal>
            <Reveal>
              <FadeInBottom>
                <div className="text-center">
                  <Image src={images.service.upts.responsive} alt="Device 최적화" />
                </div>
              </FadeInBottom>
            </Reveal>
          </Container>
        </section>

        <section className="foot">
          <Reveal threshold={0.2}>
            <FadeInBottom>
              <h5>가상 자산 거래소 솔루션 소개서</h5>
              <h6>솔루션에 더 자세한 내용은 소개서를 확인해주세요.</h6>
              <a className="btn btn-primary" href="/assets/upts.pdf" target="_blank">
                UPTS 소개서
              </a>
            </FadeInBottom>
          </Reveal>
        </section>
      </div>

      {zommImageShow && <Lightbox mainSrc={images.service.upts.diagramZoom} onCloseRequest={() => setZommImageShow(false)} />}
    </main>
  );
});
