import React, { useState, useEffect, useLayoutEffect, lazy, Suspense } from "react";
import { Container, ListGroup, Row, Spinner } from "react-bootstrap";
import { firebase_db } from "../../firebaseConfig";

// animate
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { Reveal } from "react-gsap";
import FadeInBottom from "@components/FadeInBottom";

const LazyItem = lazy(() => import("./components/ListItem"));

export default React.memo(function NewsList(props) {
  const [newsList, setNewsList] = useState([]);

  // ----------------------------------------------------------------------------------------------
  // ----------------------------------------------------------------------------------------------
  // news data
  useLayoutEffect(() => {
    const dbRef = firebase_db.ref("/news");
    dbRef.once("value").then((snapshot) => {
      let data = snapshot.val();
      setNewsList(data);
    });
    return () => {
      dbRef.off();
      if (ScrollTrigger.getById("batchAnimate") !== undefined) {
        ScrollTrigger.getAll("batchAnimate").forEach((st) => st.kill());
      }
    };
  }, []);

  // batch animate
  useEffect(() => {
    const batchAnimate = setTimeout(() => {
      ScrollTrigger.batch(".news-item", {
        id: "batchAnimate",
        start: "-50px bottom",
        onEnter: (batch) => gsap.to(batch, { opacity: 1, top: 0, duration: 0.2, stagger: 0.2 }),
      });
    }, 500);
    return () => {
      clearTimeout(batchAnimate);
    };
  }, [newsList]);

  return (
    <main id="news-list" className="noVisual">
      <Container>
        <div className="title-area">
          <Reveal>
            <FadeInBottom>
              <h1 className="title-bold-03">News</h1>
            </FadeInBottom>
          </Reveal>
        </div>
        <ListGroup variant="flush" as="ul" className="row">
          {newsList.length > 0 ? (
            newsList.map((post, index) => {
              return (
                <Suspense fallback={<div></div>} key={index}>
                  <LazyItem index={index} link={post.link} thumb={post.thumb} title={post.title} date={post.date} />
                </Suspense>
              );
            })
          ) : (
            <Reveal>
              <FadeInBottom>
                <Row className="spinner-inner no-fixed">
                  <Spinner animation="border" variant="primary" />
                </Row>
              </FadeInBottom>
            </Reveal>
          )}
        </ListGroup>
      </Container>
    </main>
  );
});
