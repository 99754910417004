/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useLayoutEffect, useRef, useMemo } from "react";
import { Container, Image } from "react-bootstrap";
import { firebase_db } from "@/firebaseConfig";

// component
import PartnerList from "./components/PartnerList";
import CertificationItem from "./components/CertificationItem";

// animate
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { Reveal } from "react-gsap";
import FadeInBottom from "@components/FadeInBottom";
import SlideUpText from "@components/SlideUpText";

// image
import Letters from "@assets/images/about/bg_letter.svg";
import LettersMobile from "@assets/images/about/bg_letter_m.svg";
import images from "@assets/images";

let lastContent;
let newContent;
export default function About(props) {
  const visualRef = useRef();

  const [historyList, setHistoryList] = useState([]);
  const [showCardIdx, setShowCardIdx] = useState(0);
  const [pinContentMarkers, setPinContentMarkers] = useState([]);
  const [pinWrapTop, setPinWrapTop] = useState();

  const pinWrapRef = useRef();
  const pinBoxRef = useRef();
  const pinContentRef = useRef();
  const scrollBoxRef = useRef();

  // certification
  const certificationList = useMemo(
    () => [
      { id: "venture", name: "벤처기업 확인서" },
      { id: "balance", name: "일생활균형 캠페인<br />참여확인서" },
      { id: "technical", name: "2021 기술역량<br />우수기업 인증서" },
      { id: "startup", name: "부산대표 기술창업기업<br />인증서" },
      { id: "security", name: "정보보호 경영<br />시스템 인증서" },
      { id: "strong", name: "부산 서비스<br />강소기업 인증서" },
      { id: "rndcenter", name: "기업부설연구소<br />인정서" },
      { id: "evaluation", name: "2021 기술평가 <br />우수기업 인증서" },
    ],
    []
  );
  const handleShowCardIdx = (idx) => {
    setShowCardIdx(idx);
  };

  // set pin panel - markers
  const setMarkers = () => {
    let markers = gsap.utils.toArray(".content-marker");
    setPinContentMarkers(markers);
    setPinWrapTop(window.pageYOffset + pinWrapRef.current.getBoundingClientRect().top);
    newContent = document.querySelector("#history_2021");
    markers.forEach((marker) => {
      marker.content = document.querySelector(`#${marker.dataset.markerContent}`);
      marker.content.enter = function () {
        gsap.fromTo(
          marker.content,
          {
            y: 100,
          },
          {
            y: 0,
            duration: 0.3,
          }
        );
      };
      marker.content.leave = function () {
        gsap.to(marker.content, {
          y: props.isSmallScreen ? "-6rem" : -100,
          duration: 0.3,
        });
      };
    });
  };
  // set pin panel - change pin contents
  const setPinPanel = () => {
    let currScroll = window.scrollY;
    pinContentMarkers.forEach((marker) => {
      let markerPosition = pinWrapTop + marker.offsetTop;
      if (currScroll > markerPosition - 90) {
        newContent = marker.content;
      }
    });
    if (newContent && (lastContent == null || !newContent.isSameNode(lastContent))) {
      if (lastContent) {
        lastContent.leave();
      }
      newContent.enter();
      lastContent = newContent;
    }
  };

  // ----------------------------------------------------------------------------------------------
  // ----------------------------------------------------------------------------------------------
  // history data
  useLayoutEffect(() => {
    const dbRef = firebase_db.ref("/history");
    dbRef.once("value").then((snapshot) => {
      let data = snapshot.val();
      setHistoryList(data);
    });
    return () => {
      dbRef.off();
      if (ScrollTrigger.getById("about_animateCoverPanel") !== undefined) {
        ScrollTrigger.getById("about_animateCoverPanel").kill();
        // console.log("kill about_animateCoverPanel");
      }
      if (ScrollTrigger.getById("about_changePinPanel") !== undefined) {
        ScrollTrigger.getById("about_changePinPanel").kill();
        // console.log("kill about_changePinPanel");
      }
      if (ScrollTrigger.getById("about_animatePinPanel") !== undefined) {
        ScrollTrigger.getById("about_animatePinPanel").kill();
        // console.log("kill about_animatePinPanel");
      }
    };
  }, []);

  // cover animate
  useEffect(() => {
    if (historyList.length > 0) {
      // animate cover panel
      ScrollTrigger.create({
        id: "about_animateCoverPanel",
        trigger: visualRef.current,
        start: "top top",
        pin: true,
        pinSpacing: false,
      });
      // set pin panel
      setMarkers();
    }
  }, [historyList]);

  // change animate
  useEffect(() => {
    if (pinContentMarkers.length > 0) {
      // animate change panel
      ScrollTrigger.create({
        id: "about_changePinPanel",
        trigger: pinWrapRef.current,
        start: "top bottom",
        end: props.isSmallScreen ? "100%+=260px center" : "bottom 300px",
        onUpdate: setPinPanel,
        // id: 'change',
        // markers: true,
      });
      // animate pin panel
      ScrollTrigger.create({
        id: "about_animatePinPanel",
        trigger: pinBoxRef.current,
        start: props.isSmallScreen ? "top top" : "top 190px",
        end: props.isSmallScreen ? "100%-=320px top" : "100%-=100px 300px",
        pin: pinContentRef.current,
        // id: 'animate',
        // markers: true,
        onEnter: () => {
          scrollBoxRef.current.classList.add("enter");
        },
        onEnterBack: () => {
          scrollBoxRef.current.classList.add("enter");
        },
        onLeaveBack: () => {
          scrollBoxRef.current.classList.remove("enter");
        },
        onLeave: () => {
          scrollBoxRef.current.classList.remove("enter");
        },
      });
    }
  }, [pinContentMarkers]);

  return (
    <main id="about">
      {/* VISUAL */}
      <div className="panel visual" ref={visualRef}>
        <section>
          <div className="bg"></div>
          <div className="bg2">{props.isSmallScreen ? <LettersMobile /> : <Letters />}</div>
          <Container>
            <div className="typo">
              <SlideUpText delay="0.2">
                <h5 className="sub-title-gray" style={{ color: "var(--bs-primary-light)" }}>
                  WE ARE UPCHAIN
                </h5>
              </SlideUpText>
              <SlideUpText delay="1.0">
                <h1>IT 엔지니어링{!props.isSmallScreen && " 경험을"}</h1>
              </SlideUpText>
              <SlideUpText delay="1.1">
                <h1>{!props.isSmallScreen ? "바탕으로 새로운" : "경험을 바탕으로"}</h1>
              </SlideUpText>
              <SlideUpText delay="1.2">
                <h1>{props.isSmallScreen && "새로운 "}내일을 준비합니다.</h1>
              </SlideUpText>
            </div>
          </Container>
        </section>
      </div>

      <div className="panel bottom">
        <div className="bg"></div>

        {/* BUSINESS  */}
        <section className="business">
          <Container>
            <Reveal>
              <FadeInBottom>
                <h5 className="sub-title-gray">BUSINESS AREA</h5>
                <h2 className="title-thin">
                  유피체인은 여러 비즈니스에{props.isSmallScreen ? <br /> : " "}
                  일괄적으로 적용할 수 있는
                  <br />
                  다양한 솔루션을 선보이고 있습니다.
                </h2>
              </FadeInBottom>
            </Reveal>
            <Reveal>
              <FadeInBottom>
                <div className="businessBox">{!props.isSmallScreen ? <Image src={images.about.business} /> : <Image src={images.about.businessMobile} />}</div>
              </FadeInBottom>
            </Reveal>
          </Container>
        </section>

        {/* PARTER */}
        <section className="partner">
          <Container>
            <Reveal>
              <FadeInBottom>
                <h5 className="sub-title-gray">INVESTOR &amp; PARTNER</h5>
                <h2 className="title-thin">
                  기술과 기술, 사람과 사람을 연결해
                  <br />큰 시너지 효과를 냄으로써
                  {props.isSmallScreen ? <br /> : " "}최상의 기술력을 발휘합니다.
                </h2>
              </FadeInBottom>
            </Reveal>
          </Container>
          <Reveal>
            <FadeInBottom>
              <div className="partners">
                <PartnerList />
              </div>
            </FadeInBottom>
          </Reveal>
        </section>

        {/* HISTORY */}
        <section className="history" ref={pinWrapRef}>
          <Container>
            <Reveal>
              <FadeInBottom>
                <h5 className="sub-title-gray">HISTORY</h5>
                <h2 className="title-thin">
                  우리는 지난 10년 동안
                  <br />
                  꾸준하게 각자의 경험과 개성을 더하여
                  <br />
                  서로의 꿈을 이어주고 있습니다.
                </h2>
              </FadeInBottom>
            </Reveal>
            <div className="list">
              <div className="pin-box" ref={pinBoxRef}>
                <div className="pin-content noAlpha" ref={pinContentRef}>
                  <div className="txtLine">
                    {historyList.length > 0 &&
                      historyList.map((history, index) => {
                        return (
                          <h3 key={index}>
                            20
                            <span id={`history_${history.year}`}>{history.year.slice(2, 4)}</span>
                          </h3>
                        );
                      })}
                  </div>
                </div>
                <div className="scroll-content" ref={scrollBoxRef}>
                  {historyList.length > 0 &&
                    historyList.map((history, index) => {
                      return (
                        <ul className="content-marker" data-marker-content={`history_${history.year}`} key={index}>
                          {history.list.length > 0 &&
                            history.list.map((item, index) => {
                              return <li key={index}>{item}</li>;
                            })}
                        </ul>
                      );
                    })}
                </div>
              </div>
            </div>
          </Container>
        </section>

        {/* CERTIFICATIONS */}
        <section className="certifications">
          <Container>
            <Reveal>
              <FadeInBottom>
                <h5 className="sub-title-gray">CERTIFICATIONS</h5>
              </FadeInBottom>
            </Reveal>
            <Reveal threshold={0.2}>
              <FadeInBottom>
                <ul>
                  {certificationList.map((card, index) => {
                    return (
                      <CertificationItem
                        key={`card_${card.id}`}
                        name={card.name}
                        id={card.id}
                        onClick={() => props.isSmallScreen && handleShowCardIdx(index)}
                        className={showCardIdx === index ? "show" : ""}
                      />
                    );
                  })}
                </ul>
              </FadeInBottom>
            </Reveal>
          </Container>
        </section>
      </div>
    </main>
  );
}
