import { useRef } from "react";
import { ScrollTrigger, Timeline, Tween } from "react-gsap";

function Card({ fromy, toy, delay }) {
  const cardRef = useRef();

  return (
    <>
      <ScrollTrigger
        trigger={cardRef.current}
        start="center 200px"
        end="600px center"
        // markers={true}
      >
        <Timeline target={<div className="card" ref={cardRef}></div>}>
          <Tween
            from={{
              opacity: 0,
              y: "-3vh",
            }}
            to={{
              opacity: 1,
              y: fromy,
            }}
            duration={0.6}
            ease="back.out(1)"
            delay={delay}
          />
          <Tween
            to={{
              y: toy,
            }}
            duration={0.6}
            ease="back.out(1)"
            delay={0.4 - delay}
          />
        </Timeline>
      </ScrollTrigger>
    </>
  );
}

export default Card;
