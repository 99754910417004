import React, { useEffect, useRef } from "react";
import { Container, Image } from "react-bootstrap";
import { images } from "@assets";

// animate
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { Reveal } from "react-gsap";
import FadeInBottom from "@components/FadeInBottom";
import SlideUpText from "@components/SlideUpText";

export default React.memo(function Nft(props) {
  const visualRef = useRef();

  // ----------------------------------------------------------------------------------------------
  // ----------------------------------------------------------------------------------------------
  useEffect(() => {
    // animate cover panel
    ScrollTrigger.create({
      id: "animateCoverPanel",
      trigger: visualRef.current,
      start: "top top",
      end: "bottom top",
      pin: true,
      pinSpacing: false,
    });

    return () => {
      if (ScrollTrigger.getById("animateCoverPanel") !== undefined) {
        ScrollTrigger.getById("animateCoverPanel").kill();
      }
    };
  }, []);

  return (
    <main id="svc-nft" className="service">
      <div className="panel visual" ref={visualRef}>
        <section className="parallax-bg" style={{ backgroundImage: `url(${images.service.nft.visual})` }}>
          <Container>
            <div className="typo">
              <SlideUpText delay="0.2">
                <h1 className="title-bold-03">UP NFT</h1>
              </SlideUpText>
              <SlideUpText delay="0.8">
                <p className="sub-title-white">
                  디지털 아이템의 소유권을 {props.isSmallScreen ? <br /> : " "}
                  기록하는 암호화 시스템
                </p>
              </SlideUpText>
              <SlideUpText delay="1.4">
                <button onClick={() => window.open("http://nft-proto.upchain.kr/", "_blank")} className="btn_link">
                  바로가기
                </button>
              </SlideUpText>
            </div>
          </Container>
        </section>
      </div>

      <div className="panel bottom">
        <section>
          <Container>
            <Reveal threshold="0.2">
              <FadeInBottom>
                <div className="typo">
                  <h2 className="title-bold-02 text-center">
                    내가 원하는 NFT를 Marketplace에서 둘러보고
                    {props.isSmallScreen ? " " : <br />}구매한 NFT를 다른 NFT 콜렉터와 자유롭게 거래할 수 있습니다.
                  </h2>
                </div>
              </FadeInBottom>
            </Reveal>
            <Reveal threshold="0.2">
              <FadeInBottom>
                <Image className="mockup" src={images.service.nft.mockup} />
              </FadeInBottom>
            </Reveal>
          </Container>
        </section>
      </div>
    </main>
  );
});
