import React, { lazy, Suspense } from "react";
import { Switch, Route } from "react-router-dom";
import RouteChangeTracker from "./RouteChangeTracker";

import Loading from "@components/layout/Loading";
import Home from "@pages/Home/Home";
import About from "@pages/About/About";
import UpWallet from "@pages/Service/UpWallet";
import Upts from "@pages/Service/Upts";
import Nft from "@pages/Service/Nft";
import Uptalk from "@pages/Service/Uptalk";
import Hmps from "@pages/Service/Hmps";
import T3 from "@pages/Service/T3";
import WorksList from "@pages/Works/List";
import WorksDetail from "@pages/Works/Detail";
import NewsList from "@pages/News/List";
import NewsDetail from "@pages/News/Detail";
import Contact from "@pages/Contact/Contact";
import Error from "@pages/Error";

// HMPS용 metaverse loading page
import Metaverse from "@pages/Metaverse";

const Layout = lazy(() => {
  return Promise.all([import("@components/layout/Layout"), new Promise((resolve) => setTimeout(resolve, 2000))]).then(([moduleExports]) => moduleExports);
});
const BlankLayout = lazy(() => {
  return Promise.all([import("@components/layout/BlankLayout"), new Promise((resolve) => setTimeout(resolve, 2000))]).then(([moduleExports]) => moduleExports);
});

function App() {
  RouteChangeTracker();

  return (
    <>
      <Suspense
        fallback={
          <div className="loading-inner">
            <Loading />
          </div>
        }
      >
        <Switch>
          <Layout exact path="/" component={Home} />
          <Layout exact path="/about" component={About} />
          <Layout exact path="/service/nft" component={Nft} />
          <Layout exact path="/service/upts" component={Upts} />
          <Layout exact path="/service/upwallet" component={UpWallet} />
          <Layout exact path="/service/t3" component={T3} />
          <Layout exact path="/service/uptalk" component={Uptalk} />
          <Layout exact path="/service/hmps" component={Hmps} />
          <Layout exact path="/works/list" component={WorksList} />
          <Layout exact path="/works/detail/:id" component={WorksDetail} />
          <Layout exact path="/news/list" component={NewsList} />
          <Layout exact path="/news/detail/:no" component={NewsDetail} />
          <Layout exact path="/contact" component={Contact} />

          <Route exact path="/metaverse" render={(routeProps) => <Metaverse {...routeProps} />} />
          {/* 404 에러페이지 */}
          <BlankLayout component={Error} />
        </Switch>
      </Suspense>
    </>
  );
}
export default React.memo(App);
