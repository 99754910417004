/* eslint-disable import/no-anonymous-default-export */
import Business from "./about/business.png";
import BusinessMobile from "./about/business_m.png";
import Partner01 from "./about/partners/01.png";
import Partner02 from "./about/partners/02.png";
import Partner03 from "./about/partners/03.png";
import Partner04 from "./about/partners/04.png";
import Partner05 from "./about/partners/05.png";
import Partner06 from "./about/partners/06.png";
import Partner07 from "./about/partners/07.png";
import Partner08 from "./about/partners/08.png";
import Partner09 from "./about/partners/09.png";
import Partner10 from "./about/partners/10.png";
import Partner11 from "./about/partners/11.png";
import Partner12 from "./about/partners/12.png";
import Partner13 from "./about/partners/13.png";
import Partner14 from "./about/partners/14.png";
import Partner15 from "./about/partners/15.png";
import Partner16 from "./about/partners/16.png";
import Partner17 from "./about/partners/17.png";

import NftVisual from "./service/nft/visual.jpg";
import NftMockup from "./service/nft/mockup.jpg";

import UptsVisual from "./service/upts/visual.jpg";
import UptsIsms from "./service/upts/isms.png";
import UptsTr from "./service/upts/tr.png";
import UptsFds from "./service/upts/fds.png";
import UptsAml from "./service/upts/aml.png";
import UptsDiagram from "./service/upts/diagram.png";
import UptsDiagramMobile from "./service/upts/diagram_m.png";
import UptsDiagramZoom from "./service/upts/diagram_zoom.jpg";
import UptsResponsive from "./service/upts/responsive.png";

import UpwalletVisual from "./service/upwallet/visual.jpg";
import UpwalletWallet from "./service/upwallet/wallet.png";
import UpwalletSecurity from "./service/upwallet/security.png";
import UpwalletStable from "./service/upwallet/stable.png";
import UpwalletStructure01 from "./service/upwallet/structure_01.png";
import UpwalletStructure02 from "./service/upwallet/structure_02.png";
import UpwalletNode from "./service/upwallet/node.png";

import Upt3Visual from "./service/upt3/visual.jpg";
import Upt3VisualTablet from "./service/upt3/visual_tablet.jpg";
import Upt3VisualMobile from "./service/upt3/visual_m.jpg";
import Upt3Small from "./service/upt3/small.png";
import Upt3Battery from "./service/upt3/battery.png";
import Upt3Ts from "./service/upt3/ts.png";
import Upt3Eft01 from "./service/upt3/effect_01.png";
import Upt3Eft02 from "./service/upt3/effect_02.png";
import Upt3Eft03 from "./service/upt3/effect_03.png";
import Upt3Eft04 from "./service/upt3/effect_04.png";
import Upt3Eft05 from "./service/upt3/effect_05.png";
import Upt3Eft06 from "./service/upt3/effect_06.png";
import Upt3Apply01 from "./service/upt3/apply_01.png";
import Upt3Apply02 from "./service/upt3/apply_02.png";
import Upt3Apply03 from "./service/upt3/apply_03.png";
import Upt3Apply04 from "./service/upt3/apply_04.png";
import Upt3Apply05 from "./service/upt3/apply_05.png";
import Upt3Apply06 from "./service/upt3/apply_06.png";
import Upt3ApplyActive01 from "./service/upt3/apply_01_active.png";
import Upt3ApplyActive02 from "./service/upt3/apply_02_active.png";
import Upt3ApplyActive03 from "./service/upt3/apply_03_active.png";
import Upt3ApplyActive04 from "./service/upt3/apply_04_active.png";
import Upt3ApplyActive05 from "./service/upt3/apply_05_active.png";
import Upt3ApplyActive06 from "./service/upt3/apply_06_active.png";

import UptalkVisual from "./service/uptalk/visual.png";
import UptalkVisualMobile from "./service/uptalk/visual_m.png";

import HmpsVisual from "./service/hmps/visual.jpg";
import HmpsVisualSmall from "./service/hmps/visual_small.jpg";
import HmpsBgMice from "./service/hmps/bg_mice.jpg";
import HmpsBgAdmin from "./service/hmps/bg_admin.jpg";
import HmpsMice01 from "./service/hmps/mice_01.png";
import HmpsMice02 from "./service/hmps/mice_02.png";
import HmpsMice03 from "./service/hmps/mice_03.png";
import HmpsMice04 from "./service/hmps/mice_04.png";
import HmpsMice05 from "./service/hmps/mice_05.png";
import HmpsAdmin01 from "./service/hmps/admin_01.png";
import HmpsAdmin02 from "./service/hmps/admin_02.png";

import ErrorIllust from "./illust_404.png";

export default {
  errorIllust: ErrorIllust,
  about: {
    business: Business,
    businessMobile: BusinessMobile,
    partner01: Partner01,
    partner02: Partner02,
    partner03: Partner03,
    partner04: Partner04,
    partner05: Partner05,
    partner06: Partner06,
    partner07: Partner07,
    partner08: Partner08,
    partner09: Partner09,
    partner10: Partner10,
    partner11: Partner11,
    partner12: Partner12,
    partner13: Partner13,
    partner14: Partner14,
    partner15: Partner15,
    partner16: Partner16,
    partner17: Partner17,
  },
  service: {
    nft: {
      visual: NftVisual,
      mockup: NftMockup,
    },
    upts: {
      visual: UptsVisual,
      isms: UptsIsms,
      tr: UptsTr,
      fds: UptsFds,
      aml: UptsAml,
      diagram: UptsDiagram,
      diagramMobile: UptsDiagramMobile,
      diagramZoom: UptsDiagramZoom,
      responsive: UptsResponsive,
    },
    upwallet: {
      visual: UpwalletVisual,
      wallet: UpwalletWallet,
      security: UpwalletSecurity,
      stable: UpwalletStable,
      structure01: UpwalletStructure01,
      structure02: UpwalletStructure02,
      node: UpwalletNode,
    },
    upt3: {
      visual: Upt3Visual,
      visualTablet: Upt3VisualTablet,
      visualMobile: Upt3VisualMobile,
      small: Upt3Small,
      battery: Upt3Battery,
      ts: Upt3Ts,
      eft01: Upt3Eft01,
      eft02: Upt3Eft02,
      eft03: Upt3Eft03,
      eft04: Upt3Eft04,
      eft05: Upt3Eft05,
      eft06: Upt3Eft06,
      apply01: Upt3Apply01,
      apply02: Upt3Apply02,
      apply03: Upt3Apply03,
      apply04: Upt3Apply04,
      apply05: Upt3Apply05,
      apply06: Upt3Apply06,
      applyActive01: Upt3ApplyActive01,
      applyActive02: Upt3ApplyActive02,
      applyActive03: Upt3ApplyActive03,
      applyActive04: Upt3ApplyActive04,
      applyActive05: Upt3ApplyActive05,
      applyActive06: Upt3ApplyActive06,
    },
    uptalk: {
      visual: UptalkVisual,
      visualMobile: UptalkVisualMobile,
    },
    hmps: {
      visual: HmpsVisual,
      visualSmall: HmpsVisualSmall,
      bgMice: HmpsBgMice,
      bgAdmin: HmpsBgAdmin,
      mice01: HmpsMice01,
      mice02: HmpsMice02,
      mice03: HmpsMice03,
      mice04: HmpsMice04,
      mice05: HmpsMice05,
      admin01: HmpsAdmin01,
      admin02: HmpsAdmin02,
    },
  },
};
